.topnav {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  padding: 10px;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
 /* border-bottom: 2px solid var(--secondary);*/
}
.contactLink{
  text-decoration: underline;
  cursor: pointer;
}
.closeBanner{
  cursor: pointer;
opacity: 0.7;
}
.loginButton{
  width: fit-content;
}
#logo {
  width: 7rem;
  margin: 8px;
  margin-left: 8px;
  cursor: pointer;
  position:relative
}
.logoContainer{
  display: flex;
  align-items: center;
  justify-content: left;
}
#logo:hover{
  filter: brightness(50%)
}
.beta{
  border-radius: 5px;
  border: 2px solid var(--main);
  width: fit-content;
  padding: 2px 4px;
  color: var(--main);
  font-weight: 700;
  font-size: 0.8rem;
}
.topnavIcons{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1rem;
}
.topnavIconsMobile{
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  text-align: center;
  margin: auto;
  z-index: 10;
  display: flex;
  justify-content: start;
  padding-top: 10vh;
  align-items: left;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-left: 20vw;
  animation: slideIn 0.5s ease forwards; /* Slide in animation when menu is loaded */


}
.topnavIconsMobile button{
  margin-bottom: 2rem;
}
.icons{
    color: var(--black);
    cursor: pointer;
    height: 2rem;
    position: relative;

}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.icons:hover{
    color: var(--darkGray);
}

.svgIcons{
  height: 1.5rem;
  width: 1.5rem;
  object-fit: fill;
  cursor: pointer;
  margin-right: 5px;
}


.notificationBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--main); /* Adjust badge background color */
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px; 
}


.searchBarContainerMob{
  width:90vw;
  margin: auto;
}

.searchBarContainer{
  width: 30vw;
  
}

.container{
  width: 100vw;
  max-width: 100%;
  margin-bottom: 1rem;
}


.link{
  all: unset;
  font-size: 1.1rem;
  font-weight: 800;
  color: var(--mainDarker);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.link:hover{
  color: var(--main);
  cursor: pointer;
}

.cartNumber{
  border-radius: 3456789px;
  font-weight: 700;
  color: white;
  background-color: var(--mainDarker);
  font-size: 10px;
  width: 20px;
  height: 20px;
  transform: translateY(-10px) translateX(-5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
}

.hambuIcon{
  fill: var(--main);
  height: 2rem;
}

.label{
  display: block;
}

.banner{
  background-color: var(--mainLighter);
  color: var(--mainDarker);
  font-weight: 700;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  text-align: center;
  width: 100%;
}
.text{
  text-align: center;
  width: 100%;
}
.banner img{
  cursor: pointer;
}
.banner img:hover{
  opacity: 0.5;
}

.hamburger{
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--mainDarker);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  z-index: 10;

}
.hamburger:hover{
  opacity: 0.7;
}

#storeDropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dropdownContent {
  position: absolute;
  top: 1.5rem;
  left: 0;
  background-color: white;
  z-index: 100;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: left;
  min-width: 20rem;
  word-wrap:normal;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0px 15px 6px 3px #0000001A;
  animation: all 10s ease-in;
  transform: translate(-12rem);
  animation: fadeIn 0.5s ease;
  border-top: 20px solid var(--bgWhite);

}
@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
#storeDropdown:hover .dropdownContent, .dropdownContent:hover .dropdownContent {
  display: flex;
}
.dropdownContent:hover .dropdownContent {
  display: flex;
  color: red !important;
}
.dropdownLink{
  width: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  padding-left: 2rem;
  padding: 0.5rem;
  border-radius: 10px;
  color: var(--mainDarker);

}

.dropdownLink:hover{
  color: var(--main);
  background-color: var(--bgWhite);

}

#storeDropdownMobile .dropdownContent{
  all: unset;
  display: block;
  padding-top: 0;
  border-left: 3px solid lightgray;
  margin-left: 2rem;
  cursor: pointer;
  color: var(--mainDarker);
  font-size: 1rem;

}

.topnavIconsMobile .link{
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  font-size: 1.1rem;
  
}

.userImage{
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 10000px;
  margin-right: 0.5rem;
  object-fit: cover;
}