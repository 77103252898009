.card{
    height: fit-content;
    display: flex;
    flex-wrap: wrap ;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 1rem;
    width: 100%;
    margin: auto;
    flex-grow: 1;
    }

.dataContainer{
    height: fit-content;
    display: flex;
    flex-wrap: wrap ;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    flex-grow: 1;
    column-gap: 1rem;
}
/*
.card > *{
    flex:1;
    flex-basis: 100px;
}
*/

.tempAlert{
    width: 100%;
    color: gray;
    font-weight: 500;
}
.image{
    height: 80px;
    width: 80px;
    object-fit: cover;
    mix-blend-mode: darken;
    border-radius: 10px;
    cursor: pointer;
}


.name{
    font-weight: bolder;
    font-size: 1.1rem;
    cursor: pointer;
    color: var(--mainDarker);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    margin: auto;
flex-grow: 3;

}
.name:hover{
    color: var(--main);

}
.price{
    font-size: 1.2rem;
    color: var(--mainDarker);
    font-weight: 900;
    text-align: right;
}
.priceCalc{
    color: gray;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    text-align: right;
    font-weight: 500;
}
.lable{
    font-weight: 700;
    color: var(--mainDarker);
    font-size: 1rem;
}

.storeName{
    display: block;
    font-weight: 600;
    text-transform: capitalize;
    color: gray;
}

.rowZero{
    width: 100%;
    text-align: right;
}

.textData{
    display: flex;
    width: 80%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    
}

.optionContainer{
    width: fit-content;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    color: var(--mainDarker);
}
.option{
    font-weight: 600;
    color: var(--mainDarker);
}
.nameContainer{
    flex-grow: 1;
    width: 30%;
    min-width: 100px;
}
.left{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-grow: 1;
}

.noteForm{
    background-color: white;
    border: transparent;
    border-radius: 10px;
    margin: auto;
    padding: 1rem 2rem;
    font-weight: 500;
    flex: 2;
    min-width: 200px;

    
}
.noteButton{
    font-size: 1rem;
}
.noteText{
    font-weight: 500;
    color: gray;
}

.noteFormContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
}
.noteFormContainer button{
    height: fit-content;
    width: fit-content;
    margin: auto;
    margin-bottom: 0;
    flex: 1;
}

.priceContainer{
    text-align: right;
    margin-right:0;
    min-width: 100px;


}
.actualPrice{
    text-align: right;
    margin: auto;
    margin-right: 0;
    width: fit-content;
}
.quantity {
    font-size: 1rem;
    font-weight: 700;
    border: none;
    color: var(--mainDarker);
  
    border-radius: 10px;
    width: 1.2rem;
    text-align: center;
    
  }
  .row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }
  
  .quantityButton {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    font-weight: 700;
    color: var(--main);
    background-color: transparent;
    font-size: 1rem;
    width: 2rem;
  }
  .quantityButton:disabled {
    filter: grayscale(100%);
  }
  .quantityButton:hover {
   color: var(--mainDarker);
  }

  .actionContainer{
    border-radius:10px;
    min-width: 5rem;
  }

  .leftRow{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  .clear{
    display: block;
    width: 100%;
    width: fit-content;
    margin: auto;
    margin-right: 0;
    margin-bottom: 0.5rem;
} 
 .clear:hover{
    opacity: 0.7;
 }

 @media only screen and (max-width: 400px){
    .left{
        justify-content: center;
    }
    .actionContainer{
        flex: 1;
    }
    .image{
        width: 70%;
        height: fit-content;
    }
}