
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: var(--bgWhite);
    padding: 1rem;
    border-radius: 10px;

}
.verticalContainer{
    padding:2rem;
    border-radius: 10px;
    padding-bottom: 1rem;
}
.image{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.name{
    color:var(--mainDarker);
    font-weight: 700;
    font-size: 0.9rem;

}