.container{
    max-width: 1000px;
    margin: auto;
}

.row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;

}
.rowReversed{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

}
.textSection{
    width: 95vw;
    max-width: 500px;
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
}

.image{
    width: 100%;
    max-width: 300px;
    width: auto;
    border-radius: 20px;
}

.imageVittorio{
    height: 200px;
    width: auto;
    border-radius: 20px;
}

.data{
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    width: 400px;
    margin: auto;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;



}
.statistic{
    font-weight: 900;
    color: var(--red);
    font-size: 2rem;
    margin: 1rem;
    margin: auto;
}

.reference{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 0.8rem;
    width: 100%;
    text-align: right!important;
    width: fit-content;
}

.text{
    color: var(--mainDarker);
    font-weight: 600;
    width: 100%;
    max-width: 500px;
    text-align: justify;
    text-justify: inter-word;
  
}


.dude{
    background-color: white;
    width: 400px;
    max-width: 95vw;
    border-radius: 20px;
    overflow: hidden;
    padding: 2rem;

}

.dude img{
    width: 100px;
    max-width: 95vw;
    aspect-ratio: 1/1;
    border-radius: 1000000px;
    margin-left: 10px;
    object-fit: cover;
}
.nameSection{
    text-align: left;
}
.name{
    font-weight: 800;
    color: var(--mainDarker);
    font-size: 1.3rem;

}
.role{
    color: gray;

}
.dudeTextSection{
    padding: 1rem;
    color: var(--mainDarker);

}

.description{
    padding: 10px;
    font-weight: 600;
}

.badge{
    text-align: center;
    justify-content: left;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.teamSection{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.enfasi{
    color: var(--main);
    font-weight: 800;
    font-size: 1.1rem;
    text-decoration: underline;

}