.card{
    width: 20rem;
    height: 10rem;
    border-radius: 5px;
    cursor: pointer;
    color: var(--mainDarker);
    overflow: hidden;
    display: flex;
    box-shadow: 1px 4px 6px 1px #0000001A;
    flex-shrink: 0;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    margin: auto;
    text-transform: capitalize;
    background-color: white;
    transition: all 0.5s ease-in-out;
    flex-shrink: 0;
    position: relative;
    flex-grow: 1;
    max-width: 400px;
}

.icon{
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
    height: 10rem;
    transition: 1s;
    object-fit: cover;
}

.card:hover{
    box-shadow: 3px 8px 9px 3px #0000001A;
    transform: translateY(-5px);

}

.name{
    font-size: 1rem;
    font-weight: 700;
    color: var(--mainDarker);
    overflow-y: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}


.textDataContainer{
    position: relative;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1rem;
}

.street{
    font-size: 0.9rem;
    font-weight: 600;
}

.tag{
    width: fit-content;
    position: absolute;
    left: 0;
    top: 5px;
    max-width: 10rem !important;
    text-wrap: wrap !important;
    
}


.city{
    font-weight: 700;
    color: gray;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}