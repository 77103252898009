.message {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  
}

.messageTop {
  display: flex;
}


.messageText {
  padding: 10px;
  border-radius: 10px 10px  10px 2px;
  background-color: var(--main);
  color: white;
  max-width: 400px;
  overflow-wrap: break-word;
  padding: 10px 20px;
  font-weight: 500;
  margin: 0;
  font-size: 1rem;
}

.messageBottom {
  font-size: 0.7rem;
  color: gray;
  font-weight: 600;
  margin-top: 0.5rem;
}

.myMessage {
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  align-items: flex-end;
}

.myMessage .messageText {
  background-color: var(--mainLighter);
  color: var(--main);
  border-radius: 10px 10px 2px 10px ;

}
.myMessage .messageTop {
  justify-content: flex-end;
}
.messageImage {

  margin-right: 0;
  width: 80%;
  max-width: 400px;
  max-height: 50%;
  border-radius: 10px;
  /*cursor: pointer;
}
.messageTop img:hover{

  filter: brightness(80%);*/
}

.tempProductContainer {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  color: var(--mainDarker);
  box-shadow: 1px 4px 6px 1px #0000001A;


}

.tempProductName {
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 1rem;
}


.addToCart {
  background-color: white;
  border-radius: 10px;

  border: none;
  outline: none;
  cursor: pointer;
  margin: 1rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-weight: 700;
}
.addToCart:hover {
  transform: scale(1.05);
}

.price {
  font-weight: 700;
  margin:1rem;
}
.messageProduct{
  max-width: 90%;
  padding-right: 2rem;
  min-height: 17rem;
}