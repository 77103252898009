.image{
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 2px;

}
.gradient{
    width: 6rem;
    height: 6rem;
        background: linear-gradient(to right, var(--main), var(--mainLighter));

    border-radius: 500px;
    padding: 4px;
    margin-bottom: 10px;
}
.card{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    cursor:pointer;
    margin: auto;
}
.gradient:hover{
    filter: brightness(0.8);
    background: linear-gradient(to right, var(--main), var(--mainLighter));
    transform: scale(1.05);

}
.name{
    font-weight: 700;
    font-size: 0.9rem;
    color: var(--mainDarker);
    width: 5rem;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to two lines */
    -webkit-box-orient: vertical;
    max-height: 2.5em;
    text-align: center;
}

.name:hover{
color: var(--main);
}