.container {
  display: flex;
  padding: 1rem;
  max-width: 100vw;
}

.actionsContainer {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 10px;
  max-width: 800px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.mobileContainer{
  display: flex;
  max-width: 100vw;
  padding: 1rem;
  flex-wrap: wrap;
}
.contentContainer {
  display: block;
  overflow: hidden;
flex: 1;
min-width: 300px;
min-height: 300px;
}

.dataContainer {
  height: inherit;
  align-items: center;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--bgWhite);
  margin: auto;
  flex-grow: 1;

}

.profileContainer {
  background-color: white;
  border-radius: 10px;
  padding: 2vw;
  margin-top: 1rem;
}
.textData {
  margin: 10px;
  gap: 1rem;
  display: flex;
}
.secondContainer {
  margin: 2rem;
}

.box {
  text-align: center;

  background-color: var(--secondary);
  padding: 1rem;
  font-weight: bolder;
  border-radius: 24px;
}

.profilePicture {
  max-width: 200px;
  width: 50px;
  height: 50px;
  fill: var(--main);
}

.noProfilePic {
  max-width: 200px;
  width: 10vw;
  height: 10vw;
  object-fit: contain;
  fill: var(--main);
}

.data {
  font-weight: 600;
  color: gray;
  margin-bottom: 1rem;
  font-size: 1rem;

}

.dataName {
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--mainDarker);
}

.bigButton {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
  margin-top: 20px;
  background-color: var(--mainLighter);
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--mainDarker);
}

.bigButton:hover {
  background-color: var(--mainDarker);
  color: white;
  fill: white;
}

.img {
  width: 150px;
  height: 150px;
  aspect-ratio: 1/1;
  object-fit: cover;
  fill: var(--main);
  border-radius: 10px;
}

.names {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-wrap: nowrap;
  flex-direction: column;
  word-break: break-word;
}

.left {
  max-width: 400px;
}
.right {
  width: fit-content;
  overflow-wrap: normal;
}
.illustration {
  width: 150px;
  height: 150px;
  object-fit: cover;
  fill: var(--main);
}

.storeSection {
  margin-top: 1rem;
  height: inherit;
  align-items: center;
  display: flex;
  gap: 1rem;
  background-color: var(--bgWhite);
  border-radius: 10px;
  padding: 1rem ;
  width: fit-content;
  justify-content: center;
  max-width: 100%;
  flex-grow: 1;
  margin: auto;
  flex-wrap: wrap;

}

.storeSectionTitle {
  font-size: 1.1rem;
  color: var(--mainDarker);
  text-align: left;
  font-weight: 700;
  margin-bottom: 1rem;
  max-width: 300px;
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: auto;
  flex-wrap: wrap;
}

.wave {
  width: 90vw;
  max-width: 400px;
}

.id {
  font-weight: 500;
  color: gray;
  font-size: 0.9rem;
}

.historyContainer {
  background-color: white;
  border-radius: 10px;
  padding: 2vw;
  margin-top: 1rem;
  max-width: 100%;
}
.clear{
  display: block;
  padding-right: 2rem;
  width: fit-content;
  margin: auto;
  margin-right: 0;
}