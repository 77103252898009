.container{
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    background-color: white;
    border-radius: 10px;

}

.icon{
    max-width: 300px;
    width: 80%;
}

.positiveMex{
    font-weight: 900;
    font-size: 1.5em;
    color: var(--main);
    margin: 1rem;
}
.positiveMexParag{
    font-weight: 600;
    font-size: 1rem;
    color: var(--mainDarker);
    text-align: center;
}
.container a{
    text-align: center;
    margin-top: 30px;

}