#svgFooter {
  bottom: 0;
  position: relative;
  width: 100vw;
  object-fit: cover;
  transform: translateY(5px);
  max-width: 100%;
}

footer {
  margin-top: 5rem;
  max-width: 100%;
  color: white;
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 1rem;
  max-width: 100%;
  padding-top:3rem ;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  background-color: var(--main);
}

.column {
  flex: 1;
  display: block;
  min-width: 200px;
  margin-bottom: 20px;
  padding-left: 10%;
}



.column ul {
  list-style: none;
  margin: 1rem;
  padding: 0;
}

.column li {
  margin-bottom: 10px;
  font-weight: 600;
}

.column a {
  color: white;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
}

.column a:hover {
  color: #dcdcdc;
  text-decoration: underline;
}

.bottom {
  max-width: 100vw;
  margin: 0 auto;
  text-align: center;
  background-color: var(--main);
}

.bottom p {
  color: white;
  font-size: 14px;
  margin: 0;
  padding: 10px 0;
}

.logo{
  filter: brightness(0) invert(1) grayscale(100%);
  height: 2rem;
}
