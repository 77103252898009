
.title{
    font-weight: 700;
    color: var(--main);
    margin: 1rem;
    text-align: center;

}

.noReviews{
    color: gray;
    text-align: center;
    font-weight: 600;
}

.card{
    background-color: white;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 2vw;
    min-height: 200px;
    max-width: 1500px;
}

.cardLink{
    color: gray;
    font-weight: 600;
    float: left;
    cursor: pointer;
    text-decoration: underline;

}
.cardLink:hover{
    color: var(--main);

}
.firstRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cardTitle{
    font-weight: bolder;
    color: var(--mainDarker);
    font-size: 1.5rem;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 5px;
}
.row{
    display: flex;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
}

.reviewsContainer{
    flex-grow: 1;
}

.statsContainer{
    position: sticky;
    width: 100%;
    max-width: 400px;
    flex-grow: 1;
    margin: auto;
}