.container{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    background-color: white;
    border-radius: 10px;
}
.icon{
    max-width: 200px;
    width: 60%;
}

.positiveMex{
    font-weight: 900;
    font-size: 1.5rem;
    color: var(--main);
    margin: 1rem;
}
.positiveMexParag{
    font-weight: 600;
    font-size: 1rem;
    color: var(--mainDarker);
    text-align: left;
    margin-bottom: 1rem;
}
.link{
    cursor: pointer;
    text-decoration: underline;
    margin: 1rem;
    font-weight: 700;
    color: var(--mainDarker);

}