.container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    font-size: 2rem!important;
    max-width: 8000px;
    margin: auto;
    margin-top: 2rem;

  }
  .container button{
    font-size: 1rem!important;
    width: 300px;

  }
  .userTypeBlock {
    width: 95vw;
    height: 300px;
    max-width: 500px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 4px 6px 1px #0000001A;

  }
  
  .userTypeBlock:hover {
    box-shadow: 3px 8px 9px 3px #0000001A;
    transform: translateY(-5px);
  }
  
  .userTypeBlock h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .userTypeBlock p {
    font-size: 14px;
    color: #666;
  }

  .icon{
    max-width: 200px;
    display: block;
    margin: auto;
}
.textContainer{
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-right: 1rem;
    margin-top: 1rem;

}
.positiveMex{
    font-weight: 900;
    font-size: 1.6em;
    color: var(--main);
    margin-bottom: 1rem;
    text-align: center;
}

.positiveMexParag{
    font-weight: 600;
    font-size: 1rem;
    max-width: 500px;
    color: var(--mainDarker);
    text-align: center;
    margin-bottom: 1rem;
    margin: auto;

}
