
.container{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1rem;
    margin: 10px;
    text-transform: capitalize;
    width: 300px;
    max-width: 100%;
}

.image{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.name{
    color: var(--mainDarker);
    font-weight: 600;
    font-size: 1.1rem;

}
.price{
    color: gray;
    font-weight: 400;
    font-size: 0.9rem;
    text-align: left !important;
    margin-top: 0.2rem;
}
.info{
    font-size: 0.9rem;

}

.quantity{
    color: gray;
    font-weight: 700;
}
.price{

    color: gray;
    font-weight: 700;
    text-align: right;
}