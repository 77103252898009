.container {
  display: flex;
  flex-wrap: nowrap;
  height: 100dvh;
  width: 50%;
  position: fixed;
  top:0;
  right: 0;
  z-index: 1000000000;
  pointer-events:all;
}
@media screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
}


.list {
  height: 100vh;
  background-color: white;
  height: 100dvh;
  width: 100%;
  padding-bottom: 3rem;
  overflow: scroll;


}
.list button {
  width: 100%;
  height: 3rrem;
  cursor: pointer;
}
.chat {
  max-height: 100%;
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
}

.newMex {
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  font-weight: 500;
  position: relative;
  height: 10dvh;
  align-items: center;
  background-color: white;
}
.attachedContainer{
  position: absolute;
  top: -200px;
  left: 0;
  height: fit-content;
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 0 10px 0 0 ;
  padding: 1rem;
  padding-top:2rem;
  box-shadow: 0px -10px 20px 5px #0000001A;

}

.newMexInput{
  width: 100%;
  border: none;
  font-weight: 600;
  background-color: white;
  border-radius: 23456789px;
  color: var(--black);
  height: 2.5rem;
  padding: 0.2rem 1rem;
  margin-left: 5px;
  border: 2px solid var(--mainDarker);


}
.newMexInput:focus{
  outline: none;

}
.newMexInput:disabled{
  all: unset;
  color: var(--mainDarker);
}
.mexContainer {
  max-height: 90vh;
  height: 90dvh;
  background-color: var(--bgWhite);
  max-width: 100%;
  padding: 1rem;
  overflow-y: auto;
  position: relative;
  cursor: auto;

}

.listTitle {
  font-weight: 900;
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: var(--main);
cursor: auto;
}


.noConversationText{
    text-align: center;
    font-weight: 800;
    color: var(--main);
    width: 100%;
    padding: 2rem;
}

.sendMessageButton{
    all: unset;
    fill: var(--mainDarker);
    color: var(--mainDarker);

    border-radius: 234567px;
    cursor: pointer;
    margin-right: 5px;
    display: flex;
    align-items: center;
}
.sendMessageButton svg{
    height: 2rem;
    width: 2rem;
    color: var(--mainDarker);
    margin-right: 0.5rem;

}
.sendMessageButton:hover{
    color: var(--main);
}

.firstRow{
  display: flex;
  background-color: white;
  align-items: center;
  padding: 1rem 1rem ;
}

.backButton{
cursor: pointer;
font-size: 1.2rem;
color: var(--main);
fill: var(--main);

width: fit-content;
}

.backButtonIcon{
  all: unset;

  color: var(--main);
  fill: var(--main);
  font-weight: 900;
    cursor: pointer;
  width: 30px;

}
.logoText{
  display: flex;
  align-items: center;
  gap: 5px;
  margin: auto;
  color: var(--mainDarker);
  font-size: 1.2rem;
  font-weight: 700;
}

.backButtonIcon:hover{
  transform: scale(1.05);
}
.backButtonList{
  all: unset;
  cursor: pointer;
  width: 100px !important;
  color: var(--main);
  font-size: 1.2rem;

  }



.backButtonListIcon:hover{
transform: scale(1.05);
}

.showButton{
  width: 80px;
  height: 80px;
  position: absolute;
  left: -85px;
  border-radius: 1000px;
  bottom: 0px;
  background-color: var(--main);
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 2px 8px 12px 2px #0000001A;
  aspect-ratio: 1/1;
  text-align: center;
  color: white;
  z-index: 10000000000000000000000000000000000000000000000000000000000000000000000000000000;


}
.showButton div{
  font-weight: 700;
}

.showButtonIcon{
  height: fit-content;
  color: white;
  aspect-ratio: 1/1;
}

.showButton:hover{
  -webkit-box-shadow: 0px 0px 15x 10px var(--mainLighter); 
  box-shadow: 0px 0px 15px 10px var(--mainLighter);
}
.totContainer{
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  height: 100dvh;
  z-index: 100;
  background-color: white;
}

.totContainerFullscreen{
  height: 85vh;
  overflow-x: scroll;
  background-color: white !important;
  border-radius: 10px;
  margin-top: 1rem;
  padding: 1rem;

}
.containerFullscreen{
  height: 100%;
  max-height: inherit;
}
.fileInput{  width: 0.1px;
height: 0.1px;
opacity: 0;
overflow: hidden;
position: absolute;
z-index: -1;

}

.fileInputLabel{
  
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: var(--mainDarker);
    fill: var(--mainDarker);
    margin-right: 1rem;
    padding: 0.5rem;
    overflow: visible;
    border-radius: 5px;
    width: 95%;
    margin: auto;
    margin-bottom: 0.5rem;

  
}
.fileInputLabel:hover{
  background-color: var(--bgWhite);
}

.fileInputLabel svg{
  width: 2rem ;
  margin-right: 0.5rem;
  height: 2rem;
  
  object-fit: fill;
}
.proposalForm{
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
  width: 95%;
  box-shadow: 1px 4px 6px 1px #0000001A;


}

.propClose{
  all: unset;
  cursor: pointer;
  color: var(--mainDarker);
  font-size: 1.3rem;
  display: block;
  margin: auto;

  margin-right: 10px;
  margin-top: 10px;

  font-weight: 900;
}
.propClose:hover{
  opacity: 0.7;

}
.shareButton{
  color: var(--mainDarker);
}
.shareButton:hover{
  opacity: 0.7;
}
.proposalContainer{
  width: 100%;
  height: 100dvh;
  background-color: rgba(128, 128, 128,0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

}

.proposalTitle{
  font-weight: 700;
  color: var(--mainDarker);
  font-size: 1rem;
  width: 80%;
  display: block;
  margin: auto;
}

.proposalForm label{
  color: gray;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  width: 80%;
  display: block;
  margin: auto;
}

.proposalForm input{
  display: block;
  margin: auto;
  font-weight: 600;
  padding-left: 1rem;
  width: 80%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  height: 3rem;

  border-radius: 10px;
  border: 2px solid var(--mainDarker);
}

.proposalForm .send{
  margin: auto;
  margin-bottom: 1rem;
}
.notification{
  background-color: var(--red);
  border-radius: 3456789px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  font-weight: 900;
  transform: translateX(35px) translateY(-10px);

}

.logo{
  height: 1.2rem;
  transform: translateY(1px);

}
.chatIllustration{
  display: block;
  margin: auto ;
  width: 50%;
  max-width: 400px;
  margin-top: 2rem;
}

.listExplain{
  width: 70%;
  max-width: 400px;
  font-weight: 600;
  margin: auto;
  text-align: center;
  color: gray;
  margin-top:1em ;

}


.tempProdButton{
  font-weight: 600;
  color: var(--mainDarker);
  cursor: pointer;
  border: transparent;
  border-radius: 23456px;
  min-height: 3rem;
  min-width: 3rem;
  margin-left: 0.5rem;

}
.tempProdButton:hover{
  opacity: 0.7;
}

.refresh{
  width: fit-content!important;
  text-align: center;
  box-sizing: border-box;
  margin: auto;

}
@keyframes rotate {
  from {
      transform: rotate(360deg);
  }
  to {
      transform: rotate(0deg);
  }
}
.turnIcon{
  animation: rotate 0.5s linear infinite; /* Here 'rotate' is the name of the animation */
}
.productListContainer{
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  width: 95%;
}
.productListContainer a{
  width: fit-content;
  margin-top: 1rem;

}
.prodRow{
  display: flex;
  max-width: 100vw;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}

.productList{
  max-height: 50dvh;
  overflow: scroll;
  margin-top: 1rem;

}

.prodTitle{
  color: var(--mainDarker);
  font-weight: 700;
  font-size: 1.2rem;
}

.imagePreview{
  height: 3rem;
  margin-left: 2rem;
}

.imagePreviewContainer{
  width: 100%;
  display: flex;

}

.removePost{
  all: unset;
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  font-weight: 800;
  border-radius: 50%;
  background-color: var(--red);
  color: var(--bgWhite);
  cursor: pointer;
  font-size: 20px;
  transform: translateY(-0.5rem) translateX(-10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}