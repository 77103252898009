.titlo{
    font-size: 1.5rem;
    color: var(--mainDarker);
    margin: 10px;
    font-weight: 900;
    text-align: left;
    text-transform: capitalize;
}

.container{
    display: flex;
    max-width: 100vw;
    padding: 1rem;
}
.mobileContainer{
    display: flex;
    max-width: 100vw;
    padding: 1rem;
    flex-wrap: wrap;
}
.contentContainer {
    display: block;
    overflow: hidden;
flex: 1;
min-width: 300px;
min-height: 300px;
}
.actionsContainer{
    text-align: right;
}

.actionsContainer button{
    margin:10px;
}

.dataContainer{
    width: 100%;
    max-width: 600px;
    height:inherit;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    padding: 20px;
}
.data{
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--main);
}


.secondContainer{

    margin: 2rem;

}
.secondContainer .title{
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
    color: var(--main);
}
.box{
    text-align: center;
    width: 300px;
    height: 400px;
    background-color: var(--secondary);
    padding: 1rem;
    font-weight: bolder;
}

.storeImg{
    height: 300px;
    text-align: center;
    margin: auto;
    display: block;
    object-fit: cover;
    border-radius: 10px;
}

.tagContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
}

.lable{
    color: gray;
    margin-top: 1rem;
    font-size: 0.9rem;

}
.storeRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: left;
    margin-top: 0.5rem;
}

.address{
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    color: var(--black);
    margin-top: 1rem;
}


.phone{
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    color: var(--black);
    margin-top: 1rem;
}



.socialIcon{
    fill: var(--main);
    height: 1.5rem;
}

.socialIcon:hover{
    fill: var(--mainDarker);
    cursor: pointer;
}
.social{
    text-align: center;
    display: flex;
    gap: 1rem;
}

.description{
    max-width: 100%;
    font-size: 1rem;
    font-weight: 500;
    color: var(--black);
    margin: auto;
    margin-top: 2rem;
    text-align: left;


}

.showMore{
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    color: var(--mainDarker);
    margin: 0.5rem;
    -webkit-box-shadow: 1px -21px 29px 38px #FFFFFF; 
box-shadow: 1px -10px 50px 38px #ffffff;
z-index: 1000;
position: relative;

}


.firstRow{
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
    
}


.owner{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 10px;
}

.userImage{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.userName{
    color: var(--black);
    font-weight: 700;

}

.carousel{
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: auto;
    padding-bottom: 2rem;
}
.noData{
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2rem;
    text-align: center;
}

.edit{
    text-align: right;
    display: block;
    margin: auto;
    margin: 1rem;
    margin-right: 3rem;
    padding-right: 2rem;
    width: fit-content;
}



.selection{
    padding: 10px 20px;
    border: none;
    display: block;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;

}

.newProduct{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin: 0;
    background-color: var(--main);
    min-width: 200px;
    width: 200px;
    height: 300px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
}


.newProduct:hover{
    background-color: var(--mainDarker);
}

.noProducts{
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2rem;
    color: gray;
}
.productsSection{
    background-color: white;
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2vw;
}

.titleRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    column-gap: 1rem;
}

.editButton{
    width: fit-content;
    white-space:nowrap
}


.searchFilter{
    box-sizing: border-box;
    height: 2.5rem;
    padding: 7px;
    width: 15rem;
    max-width: 100%;
    border: none;
    border-radius: 900000px;
    outline: none;
    padding-left: 1rem;
    color: var(--mainDarker);
    background-color: var(--bgWhite);
    font-weight: 600;
    box-shadow: 3px 4px 6px 1px #0000001A;
    margin-bottom: 1rem;


}

.productTable{
    width: 100%;
    max-width: 100%;
    border: 0px;
    table-layout: auto; /* Allow table cells to wrap content */
    border-collapse: separate;
    border-spacing: 5px 0;


}
.productImage{
    display: block;
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 5px;
}
.shortColumn{
    width: 30px;
}
.productName{
    font-weight: 700;
    color: var(--mainDarker);
    cursor: pointer;
    text-overflow: ellipsis;
    word-wrap: break-word; /* Enable text wrapping */
    font-size: 1rem;
}
.productName:hover{
    color: var(--main);

}
.productPrice, .productQuantity{
    color: gray;
    font-weight: 600;
    font-size: 1rem;
}

@media screen and (max-width: 700px) {
    .priceColumn, .productPrice{
        display: none;
    }
  }
th, td{
    text-align: left;
    color: gray;
    margin-bottom: 1rem;
    font-weight: 600;
    border: 0px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap:break-word;
white-space: normal;
padding-bottom: 1rem;


}
thead th {
    padding-bottom: 1rem;
  }
  thead th svg{
    display: block;
  }
thead{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.soldout img, .soldout .productName{
    filter: grayscale(100%);
    opacity: 0.6;
    text-decoration: line-through;
    
}
.soldout .productQuantity{
    color: var(--red);
    font-weight: 900;
}
.yellowLink{
    text-decoration: underline;
    color: var(--gold);
    font-weight: 700;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin: 0;
}

.redLink{
    text-decoration: underline;
    color: var(--red);
    font-weight: 700;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin: 0;

}

.redLink:hover, .yellowLink:hover{
    opacity: 0.7;
}