.container {
    max-width: 100%;
    overflow:hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: left;
    width: 100%;
    margin: auto;
    background-color: white;
    border-radius: 10px;
    
}
.fullContainer{
    
    background-color: white;
    border-radius: 10px;
    padding: 2vw;

}
.titleRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.carousel{
    display: flex;
    gap: 1rem;
    align-items:flex-end;
    justify-content: left;
    width: fit-content;
    max-width: 100vw;
    flex-wrap: nowrap;
    overflow: auto;
    
}
.arrow{
    width: 4rem;
}

.buttonArrow{
    background-color: transparent;
    border: none;
    fill: var(--main);
    z-index: 1000000;
    cursor: pointer;
    position: absolute;
    margin: 1rem;

}

.buttonArrow:hover{
    transform: scale(1.1);
}

.buttonArrowRight{
    background-color: transparent;
    border: none;
    fill: var(--main);
    z-index: 1000000;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin: 1rem;
    
}

.buttonArrowRight:hover{
    transform: scale(1.1);
}

.removePost{
    all: unset;
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    font-weight: 800;
    border-radius: 50%;
    background-color: var(--red);
    color: var(--bgWhite);
    text-align: center;
    cursor: pointer;
    margin: auto;
    margin-right: 0;
    font-size: 20px;
    transform: translateY(-100px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .removePost:hover{
    filter: brightness(80%);
  }

  .explanation{
    font-weight: 600;
    color: gray;
    margin-bottom: 0.5rem;
  }
  .removeSVG{
    color: var(--bgWhite);
    height: 20px;
  }
