.tagContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
    margin-bottom: 20px;
}

.tagContainerRow{
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: 1rem;
    max-width: 100%;
    overflow-x: scroll;
}

.tag{
    height: 5rem;
    aspect-ratio: 2/1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--lightYellow);
    color: var(--yellow);
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
    box-shadow: 1px 4px 6px 1px #0000001A;
    cursor: pointer;

}

.smallTag{
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--lightYellow);
    color: var(--yellow);
    font-size: 1em;
    font-weight: 700;
    text-transform: capitalize;
    box-shadow: 1px 4px 6px 1px #0000001A;
    cursor: pointer;

}