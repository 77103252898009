.name {
  font-size: 1.6rem;
  color: var(--mainDarker);
  margin-bottom: 1rem;
  margin-top: 10px;

  font-weight: 900;
  text-align: left;
  text-transform: capitalize;
}
.container {
  padding: 2vw;
}

.dataContainer {
  max-width: 100%;
  flex: 1 0 500px;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2vw;
  background-color: white;
  border-radius: 10px;
}

.firstRow {
  width: 100%;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.edit {
  width: fit-content;
  white-space: nowrap;
}
.rightButtons {
  display: flex;
  gap: 5px;
}
.shareIcon {
  width: 25px;
  margin-right: 0.5rem;
}

.secondContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.box {
  text-align: center;
  width: 300px;
  height: 400px;
  background-color: var(--secondary);
  padding: 1rem;
  font-weight: bolder;
}

.tagContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 1rem;
}

.productRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
  align-items: start;
  margin: auto;
  margin-top: 1rem;
  gap: 1rem;
  max-width: 1300px;
}

.price {
  width: 100%;
  font-size: 1.5rem;
  color: var(--main);
  font-weight: 700;
  margin-left: 1rem;
  text-align: right;
}

.price .shippingCost {
  font-size: 0.5em;
}
.actualPriceSection{
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin: auto;
  margin-right: 0;
}
.descriptionContainer {
    font-weight: 800;
    color: var(--mainDarker);
    margin-bottom: 1rem;
    padding: 10px;
    border-radius:2px 10px 10px  10px ;
    color: var(--main);
    background-color: var(--mainLighter);
    overflow-wrap: break-word;
    padding: 10px 20px;
    font-weight: 500;

}

.descriptionTitle {
  font-weight: 700;
  color: var(--mainDarker);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.chatDescriptionContainer{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;
    margin-bottom: 1rem;

}


.showMore {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  color: var(--mainDarker);
  margin: 0.5rem;
}

.label {
  font-weight: 800;
  width: fit-content;
  color: var(--mainDarker);
  font-size: 1rem;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0.5rem;
}

.lable {
  color: gray;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
}
.optionButton {
  font-size: 1rem;
  font-weight: 700;
  background-color: transparent;
  background-color: var(--bgWhite);
  border: none;
  cursor: pointer;
  color: var(--mainDarker);

  border-radius: 10px;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
}

.quantity {
  font-size: 1rem;
  font-weight: 700;
  border: none;
  background-color: var(--bgWhite);
  color: var(--mainDarker);

  border-radius: 10px;
  padding: 0.5rem;
  width: fit-content;
  text-align: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.quantityButton {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-weight: 700;
  color: var(--main);
  font-size: 2rem;
}
.quantityButton:disabled {
  filter: grayscale(100%);
}
.quantityButton:hover {
  background-color: var(--bhwhite);
}
.addToCart {
  display: block;
}

.actionContainer {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.shipping {
  font-weight: 600;
  color: gray;
  margin: 1rem;
  text-align: right;
}
.priceSection {
  text-align: right !important;
  margin: auto;
  margin-right: 0;
  display: flex;
 justify-content: right;
 flex-direction: column;
}
.price{
    background-color: red;
}
.rowa {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  font-size: 1.3rem;
  margin-top: 20px;
  flex-wrap: wrap;
}

.addToCart {
  width: fit-content;
  font-size: 1.1rem;
  flex: 1 0 10rem;
  max-width: 400px;
}

.soldOut {
  filter: grayscale(100);
  mix-blend-mode: multiply;
}

.spedizione {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 5px;
  text-align: right;
  font-weight: 600;
  font-size: 0.9rem;
  color: gray;
}
