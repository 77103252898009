.status{
    font-size: 1rem;
    text-align: right;
    color: var(--main);
    font-weight: 700;
    width: 100%;

}

.container{

    width: 100%;
    max-width: 100%;
    
    padding: 2vw;
    text-align: left;
    font-weight: 500;
    background-color: white;
    border-radius: 10px;
    transition: all 1s ease-out;
    cursor: auto;

}
.productContainer{
    flex-grow: 2;
}
.storeName{
    font-size: 1.2rem;
}
.date{
    font-size: 0.9rem;
}

.name{
    font-size: 1.2rem;
    color: var(--mainDarker);
}

.info{
    font-size: 0.9rem;

}

.showMore{
    text-decoration: underline;
    text-align: center;
    font-weight: 600;
    color: var(--black);
    width: 100%;


}


.actionList{
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    gap: 20px;
}

.productList{
    width: 100%;
    margin-top: 20px;
}

.lable{
    font-weight: 600;
    font-size: 1rem;
    margin-right: 20px;
    margin-bottom: 0.2rem;
    color: gray;
    display: flex;
    align-items: center;
    gap: 5px;
}

.player{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.bill
{
 
    font-size: 1.2rem;
    color: var(--mainDarker);
    font-weight: 700;
    width: fit-content;
    background-color: var(--bgWhite);
    border-radius: 10px;
    padding: 2vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    margin: auto;
    flex-wrap: wrap;
        margin-bottom: 1rem;

}

.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.dataRow{
    margin-bottom: 1rem;
    font-weight: 800;
    flex-wrap: wrap;
    color: var(--mainDarker);
    flex-grow: 1;
    text-align: left;


}
.dataRow>*{
    flex-basis: 100px;
}

.id{
    color: gray;
    text-align: right;
    margin: 1rem;
}

.mainRow{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
}

.mainRow >*{
    flex-grow: 1;
    word-wrap: break-all;
    word-break: break-all;
}
tbody{
    max-width: 100%;
    overflow: hidden;
}
/*...............................................*/