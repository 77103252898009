.container{
    max-width: 1000px;
    padding: 1rem;
    margin: auto;

}

.row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.textSection{
    width: 100%;
    max-width: 1000px;
}

.image{
    height: 300px;
    width: auto;
    max-width: 100%;
    object-fit: contain;

}

.data{
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    width: 400px;
    margin: auto;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;



}
.statistic{
    font-weight: 900;
    color: var(--red);
    font-size: 2rem;
    margin: 1rem;
    margin: auto;
}

.esclamation{
    font-size: 6rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: var(--red);
}

.reference{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 0.8rem;
    width: 100%;
    text-align: right!important;
    width: fit-content;
}

.text{
    color: var(--mainDarker);
    font-weight: 600;
}