
.container{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.5rem;
    margin: 2vw;
    text-transform: capitalize;
    max-width: 100%;
}

.image{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.name{
    color: var(--mainDarker);
    font-weight: 700;
    font-size: 1rem;
    overflow: hidden;
    max-width: 400px;

}

.info{
    font-size: 0.9rem;

}

.quantity{
    color: gray;
    font-weight: 700;
    word-wrap:unset;
    word-break: unset;
    min-width: 2rem;
}
.price{

    color: gray;
    font-weight: 700;
    text-align: right;
}