.contentContainer{
    margin: auto;
    border-radius: 10px;
    gap: 2rem;
    text-align: center;
    background-color: white;
    padding: 2vw;
    margin-top: 1rem;
    max-width: 95vw;

}
.left{
    align-items: center;
    text-align: center;
    width: 600px;

}

@media only screen and (max-width: 600px){
    .left{
        display: none;
    }
}
.right{
    width: 600px;
}
.logo{
    height: 5rem;
}
.motto{
    font-size: 1.5rem;
    font-family: "fredoka", sans-serif;
    color: var(--main);

}



.icon{
    height: 20rem;
    margin: 2rem;
}

.changeType{
    text-decoration: underline;
    color: var(--main);
}

.form{
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.form button{
    margin: 2rem;
}

.tot{
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    color: var(--mainDarker);
    flex: 1;
}

.spedizione{
    display: block;
    text-align: right;
    font-weight: 600;
    padding-right: 5%;
    font-size: 1rem;
    color: var(--mainDarker);
    margin-top: 1rem;

}

.noProd{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 5rem;
    color: gray;
}


.logoInText{
    height: 1.5rem;
    transform: translateY(6px);

}

.checkoutSection{
        margin: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: var(--bgWhite);
    border-radius: 10px;
    padding: 2rem;
}

.red{
    color: var(--red);
    background-color: var(--lightRed);
    font-size: 0.9rem;
    width: fit-content;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: 10px;
    margin-right: 0;
    margin-top: 1rem;

}

.svgIcons{
    width: 3rem;
    fill: var(--mainDarker);
    margin-right: 1rem;
}

.storeProd{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: auto;   
    margin-bottom: 1rem;
    flex-wrap: wrap;

}

.storeProdStore{
    background-color:  var(--mainLighter);
    border-radius: 10px;
    width: 200px;
    min-width: 100px;
    flex-grow: 1;
    padding-bottom: 1rem;

}

.rightProducts{
    background-color:  var(--bgWhite);
    border-radius: 10px;
    flex-grow: 2;
    width: 800px;
}

.shippingBento{
    border-radius: 10px;
    text-align: center;
    margin: auto;
    font-weight: 600;
    color:var(--mainDarker);
    font-size: 1rem;
    max-width: 70%;

}