.container {
  width: 70%;

  max-width: 500px;
  margin: auto;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.label {
  color: white;
  background-color: gray;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 123456px;
  font-size: 0.9rem;
}

.progressBar {
  width: 100%;
  height: 12px;
  background-color: lightgray;
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
}

.progressBarFill {
  display: block;
  height: 100%;
  background-color: var(--main);
  border-radius: 6px;
  transition: width 500ms ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.circle {
  position: absolute;
  width: 20px;
  height:20px;
  background-color: gray;
  border-radius: 50%;
  transform: translateX(-50%);
}