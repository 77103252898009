.actualForm{

    display: flex;
    flex-direction: column;
}

.actualForm label{
    color: var(--mainDarker);
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.actualForm button{
    margin: auto;
    margin-top: 1rem;
}
.actualForm input{

    max-width: 300px;
    border-radius: 5px;
    border: 2px solid var(--main);
    font-weight: 700;
    padding-left: 1rem;

}
.actualForm textarea{

    max-width: 300px;
    border-radius: 5px;
    border: 2px solid var(--main);
    font-weight: 700;
    padding-left: 1rem;

}


.formContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 1rem;
}

.imgForm{
    max-width: 200px;

}

.question{
    font-weight: 800;
    color: var(--mainDarker);
    display: flex;
    justify-content: space-between;

}
.answer{
    padding: 1rem;
    font-weight: 500;
}

.text{
    color: var(--mainDarker);
    font-weight: 600;
}

.or{
    color: var(--mainDarker);
    font-weight: 600;
    display: block;
    margin: auto;
    width: fit-content;
    margin-top: 1rem;
    text-align: center;
}