.tagIcon{
    width: 1.1rem;
    color: var(--mainDarker);
}

.container{
    background-color: var(--mainLighter);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 700;
    font-size: 0.8rem;
    color: var(--mainDarker);
    cursor: pointer;
    text-transform: capitalize;
    transition: all 0.5s ease-in-out;
    width: fit-content;
    text-wrap: nowrap;
}


.container:hover{
opacity: 0.6;
}

.smallContainer{
    transform: scale(90%);
    text-wrap: wrap;
    width: fit-content;
}