.container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    background-color: white;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 2vw;
}


.positiveMex{
    font-weight: 900;
    font-size: 1.5em;
    color: var(--mainDarker);
    text-align: center;
    margin: 1rem;
}
.positiveMexParag{
    font-weight: 700;
    font-size: 1.1rem;
    color: var(--mainDarker);
    text-align: center;
}
.container a{
    text-align: center;
    margin-top: 30px;

}

.card{
    background-color: white;
    border-radius: 10px;
    margin: auto;
    padding: 2vw;
    width: 200px;
height: 300px;
display: flex;
justify-content: space-evenly;
flex-direction: column;
border: 2px solid var(--main);
font-weight: 600;
text-align: center;
box-shadow: 3px 8px 9px 3px #0000001A;

}


.price{
    color: var(--main);
    font-size: 1.5rem;
text-align: center;
font-weight: 800;
text-shadow:  0px 0px 10px var(--mainLighter);


}

.smallPrice{
    font-size: 1.2rem;
}

.titleRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.editButton{
    width: fit-content;
    white-space:nowrap
}
.explanation{
    font-weight: 700;
    color: gray;
    width: 100%;

}
.dataContainer{
    display: flex;
    align-items: center;
justify-content: center;
margin-bottom: 1rem;
flex-wrap: wrap;
gap: 1rem;

}
.num{
    font-weight: 900;
    color: var(--mainDarker);
    font-size: 1.1rem;
    margin: 5px;
    width: fit-content;
}

.data{
    background-color: var(--bgWhite);
    border-radius: 10px;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-grow: 1;
    margin: auto;
}

.dataTitle{
    font-weight: 700;
    color: var(--main);
    width: fit-content;
}

.data svg{
    height: 2.5rem;
    width: 2.5rem;
    color: var(--main);
}

.options{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2rem;
}

.discount{
    color: gray;
    text-decoration: line-through;
}

.star{
    color: gold;

}