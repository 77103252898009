.card {
  width: 13rem;
  height: 17rem;
  border-radius: 5px;
  cursor: pointer;
  color: var(--mainDarker);
  overflow: hidden;
  box-shadow: 1px 4px 6px 1px #0000001a;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin: auto;
  text-transform: capitalize;
  background-color: white;
  flex-grow: 1;
  max-width: 17rem;
  position: relative;
}

.icon {
  height: 9rem;
  width: 100%;
  transition: 1s;
  object-fit: cover;
}

.noIcon {
  height: 9rem;
  display: block;
  margin: auto;
  mix-blend-mode: darken;
  transition: 1s;
  object-fit: scale-down;
  object-position: center;
}

.card:hover {
  box-shadow: 3px 8px 9px 3px #0000001a;
  transform: translateY(-5px);
}

.name {
  font-weight: 700;
  font-size: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.price {
  font-size: 1rem;
  font-weight: 700;
  color: var(--mainDarker);
  margin-bottom: 5px;
}

.textDataContainer {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
  box-sizing: border-box;
  transform: translateY(-40px);
}

.badge {
  background-color: var(--lightYellow);
  color: var(--yellow);
  padding: 0.7rem;
  width: fit-content;
  border-radius: 20px;
  font-size: 0.8rem;
  text-align: right;
}

.priceSection {
  width: fit-content;
  padding: 0.5rem 1rem;
  max-width: 100%;
  background-color: white;
  border-radius:  5px 0px 0 0;
  margin-left: auto;
  margin-right: 0;
  transform: translateX(10px);

}

.star {
  color: var(--main);
  display: flex;
  align-items: flex-end;
  font-size: 0.9rem;
  font-weight: 800;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 2px;
}
.star svg {
  height: 1.2rem;
  width: fit-content;
}

.disabled{
    filter: grayscale(100%);
}


.banner {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  background-color: gray; /* Adjust color as needed */
  color: white; /* Adjust color as needed */
  padding: 4px 8px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 2;
}