.digits{
    font-size: 0.9rem;

}

.priceTag{
    width: fit-content;
    display: block;
    font-size: 1rem;
    color: var(--main);
    /*background-color: var(--mainLighter);
    padding: 0.5rem 1rem;*/
    border-radius: 1000px;
    font-weight: 800;
}

.priceTagBig{
    width: fit-content;
    display: block;
    font-size: 1.4rem;
    color: var(--main);
    /*background-color: var(--mainLighter);
    padding: 10px 20px;*/
    border-radius: 1000px;
    font-weight: 800;
}

.container{
    width: fit-content;
    word-break: keep-all;
    word-wrap: unset;
}