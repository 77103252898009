.userImage{
    height: 70px;
    width: 70px;
    border-radius: 14567890px;
}
.userIcon{
    height: 3rem;

}
.userData{
    display: flex;
    align-items: center;
}
.userName{
    font-weight: 700;
    color: var(--mainDarker);
    margin: 1rem;
}
.container{
    width: 100%;
    margin: auto;
    margin-top: 1rem;
    background-color: white;
    border-radius: 20px;

}
.containerDisabled{
    background-color: lightgray;
    opacity: 0.8;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;


}
.firstRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-weight: 600
}

.ratingsContainer{
    display: flex;
    row-gap: 0.5rem;
    column-gap:2rem;
    align-items: center;
    justify-content: right;
flex-wrap: wrap;
flex: 1 0 200px;

}

.rating{
    text-align: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.ratingValue{
    font-size: 1.2rem;
    font-weight: 900;
    color: var(--main);
}
.ratingLabel{
    font-size: 1rem;
    font-weight: 600;
    color: gray;
}

.content{
    padding: 1rem;
    color: var(--mainDarker);
       margin: auto;
    font-weight: 600;


}

.date{
    color: var(--darkGray);
    margin-top: 3px;
    padding-left: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: left;
}

.deleteButton{
    all: unset;
    text-decoration: underline;
    color: var(--red);
    font-weight: 700;
    text-align: right;
    width: 100%;
    cursor: pointer;
}