.chart {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    max-width: 600px;

  }
  .container{
    flex-grow: 1;
width: 100%;
    background-color: var(--bgWhite);
    border-radius: 10px;
    padding: 2rem;
    position: sticky;
    top: 1rem;
  }
  .barContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    column-gap: 10px;
  }
  
  .bar {
    width: 90%;
    height: 20px; /* Adjust height as needed */
    background-color: white;
    border-radius:50px;
  }
  
  .fill {
    height: 100%;
    background-color: var(--main);
    border-radius:50px;

  }
  
  
  .label {
    font-size: 0.8rem;
    font-weight: 700;
    width: 100px;
    color: var(--mainDarker);

  }

  .num{
    color: gray;
    font-weight: 600;
  }

  .star{
    color: var(--mainDarker);
    font-weight: 700;
  }

  .title{
    font-weight: 700;
    color: var(--mainDarker);
    font-weight: 1.1rem;
    margin-bottom: 0.5rem;

  }