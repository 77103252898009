.container{
    max-width: 1000px;
    margin: auto;
    background-color: white;
    padding-bottom: 2rem;
    border-radius: 10px;
    margin-top: 1rem;
}
.sectionContainer{
    margin: auto;
    background-color: white;
    padding-bottom: 2rem;
    border-radius: 10px;
    margin-top: 1rem;
}
.item{
    border-radius: 20px;
    
    background-color: var(--bgWhite);
    margin: 1rem;
    padding: 1rem;

    cursor: pointer;

}

.img{
    width: 200px;
    max-width: 100%;
    margin:auto;
    display: block;
}

.formContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.imgForm{
    width: 200px;

}

.question{
    font-weight: 700;
    color: var(--mainDarker);
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.answer{
    padding: 1rem;
    font-weight: 500;
}

.actualForm{

    display: flex;
    flex-direction: column;
}

.actualForm label{
    color: var(--mainDarker);
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

.actualForm button{
    margin: auto;
    margin-top: 1rem;
}
.actualForm input{

    width: 300px;
    border-radius: 5px;
    border: 2px solid var(--main);
}
.actualForm textarea{

    width: 300px;
    border-radius: 5px;
    border: 2px solid var(--main);
}

.openClose{
    font-size: 1.5rem;
    color: var(--main);
}

.rotate{
    rotate: 45deg;
}