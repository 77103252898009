.container{
    padding: 1rem;
    min-height: 50vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.negativeMex{
    font-weight: 900;
    font-size: 2rem;
    color: var(--red);
    text-align: center;

}
.positiveMex{
    font-weight: 900;
    font-size: 2rem;
    color: var(--main);
    text-align: center;

}

.link{
    cursor: pointer;
    text-decoration: underline;
    margin: 1rem;

}