.modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.5);

}
.button{
  width: 100%;
}
.button:hover{
  fill: var(--main)!important;
}
.modal.show {
  display: flex;
}

.modalContent {
  
  margin: 15% auto;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;

}
.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content{
  padding: 1rem 0rem ;
  font-weight: 500;
  color: var(--mainDarker);
}
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.title{
  color: var(--mainDarker);
  font-weight: 800;
  font-size: 1.3rem;

}
.message {
  font-size: 18px;
  margin-bottom: 20px;
}

.delete-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #d32f2f;
}
