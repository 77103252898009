.container {
    display: flex;
    align-items: center;
    margin: 10px;
  }
  
  .dataContainer {
    height: fit-content;
    width: fit-content;
    width: 100%;
    max-width: 1000px;
    background-color: white;
    padding: 2vw;

    border-radius: 20px;
  
    text-align: center;
    margin: auto;
    padding: 1rem;
    border-radius: 20px;
    text-align: center;
  }

.logo{
    width: 50%;
    min-width: 100px;
    cursor: pointer;
}

.changeType{
    text-decoration: underline;
    color: var(--main);
    cursor: pointer;
}


.form button{
    margin: auto;
    margin-top:1em ;
    margin-bottom:1em ;

}

.titleForm{
    font-size: 2rem;
    width: fit-content;
    padding: 1rem;
    margin: auto;
    font-weight: 900;
    color: var(--main);

}

.input{
    border-radius: 5px;
    width: 100%;
    display: block;
    height: 40px;
    border: 2px solid var(--main);
    padding: 5px;
    margin: auto;

    padding-left: 20px;
    font-size: 1rem;
    font-weight: 500;

}

.addImageIcon{
    width: 2rem;
    fill: var(--mainDarker);
}


.inputContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 500px;
    margin: auto;
}
.inputContainer> div{
    width: 450px;
}

.tagForm{
    border-radius: 5px;
    width: 100%;
    border: 2px solid var(--main);
    padding: 10px;
    padding-left: 20px;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.tagForm .tagInput{
    width: 90%;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 500;
    min-width: 50px;
    border-bottom: 1px solid var(--main);
    padding-bottom: 5px;

}

.tagButton{
    box-sizing: border-box;
    width: 10%;
}
.inputAndButton{
    display: flex;
    width: 100%;
}
.tag{
    background-color: var(--main);
    border-radius: 20px;
    padding: 0.7rem;
    color: white;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
}
.removeTag{
    font-weight: 900;
    cursor: pointer;
    color: var(--bgWhite);
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    align-items: center;
}
.removeTag:hover{
  opacity: 0.7;

}
.inputTextArea{
    border-radius: 5px;
    width: 100%;
    display: block;
    border: 2px solid var(--main);
    padding: 5px;
    margin: auto;

    margin-top: 1rem;
    padding-left: 20px;
    
    font-size: 1rem;
    font-weight: 500;
    height: 150px;
  }

  
.dropFile{
  height: 5rem;
  width:5rem;
    padding: 0;
    margin: 0;
    object-fit: cover;
    border-radius: 10px;
    background-color: var(--mainLighter);
    color: var(--main);
    font-weight: 900;
    padding-top: 1rem;
    font-size: 1rem;
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    margin: auto;
  
    cursor: pointer;
  }
  .improveButton{
    width: fit-content !important;
    margin: auto;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .improveButton svg{
    margin-right: 0.5rem;
    fill: var(--gold);
    color: var(--gold);
  }
  .removeOldImage{
    all: unset;
    height: 30px;
    width: 30px;
    font-weight: 800;
    border-radius: 50%;
    background-color: var(--lightRed);
    color: var(--red);
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0 !important;
    transform: translateX(-15px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .newImageContainer{
    margin: 0.25rem;
    display: flex;
    align-items: flex-start;
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
  }
  

  .movementButton{

    all: unset;
    cursor: pointer;
    color: var(--mainDarker);
    font-weight: 700;   
     display: flex;
    align-items: center;

  }
  .movementButton:hover{
    color: var(--main);
  }
  .movementButton:disabled{
    color: lightgray;
  }
  .buttonContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items:start;
  }
.selectTagContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}
.selectTag{
  border: 2px solid var(--main);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  padding: 5px;


}

.error{
  border: 2px solid var(--red);
  color: var(--red);;
}
.errorMessage{
  color:var(--red);
  font-weight: 700;
  text-align: left;
  text-decoration: dotted;
  margin: 0;
  padding: 0;
}
.errorMessageFinal{
  max-width: 500px;
  color:var(--red);
  font-weight: 700;
  text-align: right;
  margin: auto;

}

.option{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main);
  padding: 0.5rem 1rem;
  border-radius: 12345px;
  color: var(--bgWhite);
  font-weight: 600;
  
}

.oldImage{
  height: 5rem;
  width:5rem;
  object-fit: cover;
  border-radius: 10px;
}

.removeOldImage{
  all: unset;
  height: 30px;
  width: 30px;
  font-weight: 800;
  border-radius: 50%;
  background-color: var(--lightRed);
  color: var(--red);
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 !important;
  transform: translateX(-10px) translateY(-10px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oldImageContainer{
  margin: 0.5rem;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.newImageContainer{
  margin: 0.25rem;
  display: flex;
  align-items: flex-start;
}


.totOldImagesContainer{
  margin: 0.5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
}

.label{
  font-weight: 600;
  color: gray;
  text-align: left;
  margin: 0.5rem;
}

.removeSVG{
  fill: var(--red);
  height: 18px;
}

.checkbox{
  margin-top: 2rem;
  font-weight: 600;
}

.image{
  max-width: 70px;
  border-radius: 1000%;
}
.Tralevie{
  font-weight: 800;
  font-size: 1.2em;
  color: var(--mainDarker);
}

.chatRow{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  margin: auto;
  gap: 1em;
}

.message{
  text-align: left;
  font-weight: 700;
  font-size: 1em;
  color: var(--mainDarker);
  padding: 10px;
  border-radius: 2px 10px 10px 10px ;
  background-color: var(--main);
  color: white;
  max-width: 500px;
  overflow-wrap: break-word;
  padding: 15px 25px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.recapRecordError{
  color: red;
}
.recapRecord{
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.radio{
  display: block;
  background-color: var(--bgWhite);
  margin: 1rem;
  text-align: left !important;
  padding: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;

}
.radioSelected{
  display: block;

  background-color: var(--mainLighter);
  border: 2px solid var(--main);

  margin: 1rem;
  text-align: left !important;
  padding: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;

}


ul{
  margin: 0;
  list-style-type: none;
padding: 0;
}
