.sidebar{
    height: 88vh;
    min-width: fit-content;
    width: 15vw;
    padding: 1rem;
    position: sticky;
    top: 1rem;
    background-color: white;
    border-radius: 10px;
    margin-right: 1rem;
    
}
.mobileSidebar{
    min-width: fit-content;
    top: 1rem;
    background-color: transparent;
    border-radius: 10px;
    margin-right: 1rem;
    height: fit-content;
    
}
.mobileSidebar ul{
    display: flex;
    max-width: 100%;
    width: 90vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-bottom: 1rem;
}
.mobileSidebar .link {
    display: block;
    margin: auto;
    text-align: center;
}
.mobileSidebar .link svg{
    margin-right: 0;
}
.link{
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 10px;
    color: var(--mainDarker);
    display: flex;
    align-items: center;
       cursor: pointer;
}
.link svg{
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 10px;
}
.link:hover{
    background-color: var(--bgWhite);
    color: var(--main);
}

.linkSelected{
    background-color: var(--mainLighter);
}