.card{
    height: fit-content;
    display: flex;
    flex-wrap: wrap ;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    column-gap: 1rem;
    background-color: white;
    border-radius: 20px;
    margin: auto;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 95vw;
 
 
}
.inventory{
    color: gray;
    font-weight: 600;
}

  .image{
    height: 80px;
    aspect-ratio: 1/1;
    mix-blend-mode: darken;
    border-radius: 20px;
    cursor: pointer;
}

.name{
    padding: 0.5rem;
    font-weight: 700;
    font-size: 0.9rem;
    cursor: pointer;
    color: var(--mainDarker);
    text-align: left;
}


.textDataContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
}



.buttonContainer{
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-between;
    padding: 0 1rem;
    gap: 1rem;
}

.buttonContainer button{
    width: fit-content;
}