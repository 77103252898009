.form {
  max-width: 1000px;
  width: 95%;
  display: flex;
  align-items: center;
  height: 40px;
  background-color: white;
  border-radius: 24px;
  overflow: hidden;
  font-weight: 700;
  box-shadow: 1px 4px 6px 1px #0000001A;
  color: var(--mainDarker);
  border: 2px solid var(--main);
  justify-content: center;
}
#content {
  box-sizing: border-box;
  height: 50px;
  padding: 10px;
  width: 90%;
  border: none;
  outline: none;
  padding-left: 1rem;
  color: var(--mainDarker);
  font-weight: 600;
  background-color: transparent;

}
.container {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 2vw;
 border-radius: 10px;
  max-width: 95%;
  background-color: white;
  width: fit-content;
  margin: auto;
  margin-bottom: 1rem;
}

.container a {
  text-align: center;
}
.userImage {
  height: 100px;
  width: 100px;
  border-radius: 14567890px;
}
.userIcon {
  height: 50px;
}
.userData {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}
.userName {
  font-weight: 700;
  color: var(--mainDarker);
  margin: 1rem;
}

.title {
  font-weight: 700;
  color: var(--mainDarker);
  margin-bottom: 1rem;
  padding: 10px;
  border-radius:2px 10px 10px 10px ;
  background-color: var(--main);
  color: white;
  overflow-wrap: break-word;
  padding: 10px 20px;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 4rem;

}


.messageSent {
  font-weight: 700;
  color: var(--mainDarker);
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 10px 10px 2px 10px ;
  color: var(--main);
  background-color: var(--mainLighter);
  overflow-wrap: break-word;
  padding: 10px 20px;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-left: 4rem;
  margin-right: 2rem;
}
.continue{
  text-align: center;
  color: var(--mainDarker);
  font-weight: 600;
}

.submitButton {
  height: 50px;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendIcon {
  color: var(--mainDarker);
  height: 30px;
}

.closeButton {
  all: unset;
  cursor: pointer;
  opacity: 0.6;
  font-weight: 900;
  font-size: 1.5rem;
  text-align: right;
  width: fit-content;
  margin: auto;
  margin-right: 0;
  color: var(--red);
  float: left;
}
.right{

  background-color: var(--bgwhite);
}

.image{
  width: 200px;
  
  border-radius: 10px;
}

.name{
  font-size: 1.4rem;
  font-weight: 900;
  color: var(--mainDarker);
}

.right{
  width: 400px;
  max-width: 100%;
}

.textSection{
  font-weight: 600;
  margin-left: 10px;
  color: gray;
}