.container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  animation: all 1s ease-in-out;
}

.carousel {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: left;
  max-width: 100%;
  flex: 1;
  overflow-x: scroll;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 3rem;
  height: 100%;
  padding: 1rem 0.2rem;
}
.arrow {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--main);
  filter: drop-shadow(0px 0px 4px var(--main));
}
.container:hover > .buttonArrow,
.container:hover > .buttonArrowRight {
  visibility: visible;
}
.buttonArrow {
  visibility: hidden;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: var(--main);
  z-index: 10;
  cursor: pointer;
  opacity: 0.8;
  width: 5%;
}

.buttonArrow:hover {
  opacity: 1;
  transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .arrow {
    visibility: hidden;
  }
}

.noProduct {
  min-width: 180px;
  width: 180px;
  height: 250px;
  border-radius: 10px;
  color: var(--mainDarker);
  overflow: hidden;
  box-shadow: 1px 4px 6px 1px #0000001a;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin: auto;
  text-transform: capitalize;
  background-color: white;
  font-weight: 800;
  text-align: center;
  flex-wrap: nowrap;
  padding: 1rem;
}
.padding {
  padding: 1rem 1rem;
}
.voidList{
  display: block;
  text-align: center;
  color: gray;
  font-weight: 700;
  width: 100%;

}
.noProduct svg {
  display: block;
  fill: var(--mainDarker);
  width: 3rem;
  margin: auto;
  margin-top: 50px;
}
.fadeRight {
  position: absolute;
  top: 0;
  right: 0;

  display: block;
  width: 10vw; /* Adjust as needed */
  height: 100%; /* Fill entire height */

  /*background: linear-gradient(to right, rgba(255, 255, 255, 0), white 90%); Fade effect */
  pointer-events: none; /* Ensure the fade effect does not interfere with scrolling */
  z-index: 2;
}

.bar {
  width: 100px;
  margin: auto;
  height: 10px; /* Adjust height as needed */
  background-color: gainsboro;
  border-radius: 50px;
}

.fill {
  height: 100%;
  background-color: var(--main);
  border-radius: 50px;
}
