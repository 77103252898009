.container{
    text-align: center;
    margin:10px;
    min-height: 80dvh;
    
}

.dataContainer{
    height: fit-content;
    width: 100%;
    max-width: 500px;


    text-align: center;
    margin: auto;
    padding: 2rem;
    border-radius: 10px 10px 0 0 ;
    text-align: center;
    background-color: white;
    margin-top: 20px;
}
.textContainer{
    font-weight: 700;
    color: var(--mainDarker);
}

.logo{
    width: 50%;
    min-width: 100px;
    cursor: pointer;
}

.changeType{
    text-decoration: underline;
    color: var(--main);
    cursor: pointer;
    font-weight: 700;
}
.actionButton{
    width: 90%;
    margin-top: 0.5rem;
}

.form button{
    margin: auto;
    margin-top:1.5em ;
    margin-bottom:1em ;

}

.titleForm{
    font-size: 2rem;
    width: fit-content;
    padding: 1rem;
    margin: auto;
    font-weight: 900;
    color: var(--main);

}

.input{
    border-radius: 5px;
    width: 90%;
    display: block;
    height: 40px;
    border: 2px solid var(--main);
    padding: 5px;
    margin-top: 1rem;
    padding-left: 20px;
    font-size: 1rem;
    font-weight: 500;

}
.input:disabled{
    border: 2px solid gray;
}

.addImageIcon{
    width: 2rem;
    fill: var(--mainDarker);
}


.dropFile{
    height: 80px;
    min-width: 80px;
    padding: 0;
    margin: 0;
    width: 80px;
    object-fit: cover;
    border-radius: 10px;
    background-color: var(--mainLighter);
    color: var(--main);
    font-weight: 900;
    padding-top: 1rem;
    font-size: 1rem;
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    margin: auto;
  
    cursor: pointer;
  }

  
  .removeOldImage{
    all: unset;
    height: 30px;
    width: 30px;
    font-weight: 800;
    border-radius: 50%;
    background-color: var(--lightRed);
    color: var(--red);
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0 !important;
    transform: translateX(-15px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newImageContainer{
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
    margin-top: 0.5rem;
    object-fit: cover;
    padding: 0;
  }
  

.inputContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
    column-gap: 1rem;
}
.inputContainer> *{
    width: 90%;
}


.agreement{
    color: gray;
    margin: 1rem;
}

.agreement a{
    color: var(--main);
}


.smallInput{
    width: 43%;
}

.wave{
    width: 90%;
    max-width: 500px;
}

.avatarIcon{
    max-width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;

}

.imageContainer{
    position: relative;
    display: block;
    height: 200px;
    width: 200px;
    margin: auto;
}

.error{
    border: 2px solid var(--red);
    color: var(--red);;
  }
  .errorMessage{
    color:var(--red);
    font-weight: 700;
    text-align: left;
    text-decoration: dotted;
    margin: 0;
    padding: 0;
  }