.container {
    max-width: 100%;
    overflow:hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px ;
    width: fit-content;
    margin: auto;
    margin-bottom: 1rem;
}

.carousel{
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: left;
    width: fit-content;
    max-width: 100vw;
    flex-wrap: nowrap;
    overflow: auto;
    
}
.arrow{
    width: 4rem;
}

.buttonArrow{
    background-color: transparent;
    border: none;
    fill: var(--main);
    z-index: 1000000;
    cursor: pointer;
    position: absolute;
    margin: 1rem;

}

.buttonArrow:hover{
    transform: scale(1.1);
}

.buttonArrowRight{
    background-color: transparent;
    border: none;
    fill: var(--main);
    z-index: 1000000;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin: 1rem;
    
}

.buttonArrowRight:hover{
    transform: scale(1.1);
}

.removePost{
    all: unset;
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    font-weight: 800;
    border-radius: 50%;
    background-color: var(--lightRed);
    color: var(--red);
    margin: auto;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    margin-left: -30px;
    transform: translateY(-130px) translateX(-20px);
    z-index: 10;
  }