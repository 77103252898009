.rowContainer{
    background-color: white;
    width: 90vw;
    max-width: 700px;
    padding: 2em 2em 2em 2em ;
    border-radius: 20px 20px 0 0;
    margin: auto;
    margin-bottom: 0px;
    font-weight: 500;

}
.link{
    color: var(--main);
    text-decoration: underline;
    cursor: pointer;
}

.teacher{
    width: 80vw;
    max-width: 400px;
    margin: auto;
}

.wave{
    width: 90vw;
    max-width: 700px;
    margin: auto;
    display: block;

}
.firstContent{
    display: flex;
    align-items: start;
    justify-content: center;
    max-width: 1000px;
    gap: 1rem;
    margin: auto;
    margin-bottom: 2rem;
    flex-wrap: wrap;


}

.textCont{
    max-width: 500px;
    width: 90vw;
    font-weight: 600;
}
.textCont div{ 
    margin-top: 1rem;
}