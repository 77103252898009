

.description{
    font-size: 1rem;
    font-weight: 500;
    color: var(--black);
    width: 100%;
    max-width: p;
    overflow: hidden;
}

.showMore{
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    color: var(--mainDarker);
    margin: 0.5rem;
    margin: auto;
    width: 100%;

position: relative;

}

.shadow{
    -webkit-box-shadow: 0px -18px 15px 6px white; 
    box-shadow: 0px -18px 15px 6px white;
}

