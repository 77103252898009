
.text{
  font-size: 12px;
}
.text .ldsEllipsis div {
  background: var(--mainLighter) !important;
}
.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 4em;
    height: 1em;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;

}
  .ldsEllipsis div {
    position: absolute;
    top: 0.1em;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background: var(--mainLighter);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsEllipsis div:nth-child(1) {
    left: 0.4em;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsEllipsis div:nth-child(2) {
    left: 0.4em;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsEllipsis div:nth-child(3) {
    left: 1.6em;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsEllipsis div:nth-child(4) {
    left: 2.8em;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(1.2em, 0);
    }
  }
  

  .gif{
    height: 60px;
    padding: 1rem;
    background-color: transparent;
    mix-blend-mode:darken;
    border-radius: 20px;
    z-index: 100000000000000000000000000000;

  }

  .fullScreenContainer{
    position: static;
    top: 0;
    left: 0;
    background-color: var(--bgWhite);
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000000000000000000000000000;
  }

  

  
/* Loader 1 */
.loader {
	height: 32px;
	width: 32px;
	-webkit-animation: loader-1-1 4.8s linear infinite;
	        animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(0, 32px, 32px, 16px);
	-webkit-animation: loader-1-2 1.2s linear infinite;
	        animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(0, 32px, 32px, 16px);
	border: 4px solid var(--main);
	border-radius: 50%;
	-webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-1-3 {
	0%   { -webkit-transform: rotate(-140deg); }
	50%  { -webkit-transform: rotate(-160deg); }
	100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}
