
  .container{
    display: block;
    text-align: right;
    width: 100%;
  }

  .icon{
  
    width: 20px;
    text-align: right;
    font-size: 15px;
    height: 20px;
    border-radius: 50%;
    color: gray;
    font-weight: 800;
    border: 2px solid gray;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin: auto;
    margin-right: 0;

  }

  .content{
    color: gray;
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
  }