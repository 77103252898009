.container{
 min-height: 100vh;
 background-color: var(--main);
 padding: 2rem;
 padding-top: 100px ;
}

.icon{
    max-width: 100%;
    width: 200px;
    display: block;
    margin: auto;
    color: white;
    fill: none;
}

.text{
    font-weight: 800;
    font-size: 2.5rem;
    color: white;
    width: fit-content;
    margin: auto;
    text-align: center;
    margin-top: 2rem;
}