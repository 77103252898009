.carousel{
    width: 100%;
    max-width: 1000px;
    margin: auto;

}

.carousel button{
    margin-bottom: 1rem;
}

.searchFilter{
    box-sizing: border-box;
    height: 40px;
    padding: 7px;
    width: 300px;
    border: none;
    border-radius: 900000px;
    outline: none;
    padding-left: 1rem;
    color: var(--mainDarker);
    font-weight: 600;
    box-shadow: 3px 4px 6px 1px #0000001A;


}

.row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}