.activeImg{
    width: 100%;
    max-width: 500px;
    object-fit: contain;
    cursor: pointer;
    border-radius: 10px;

}

.loading{
    width: 500px !important;
    height: 400px;
}
.productImg{
    max-width: 60px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;

}
.fullImageList{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

}

.imagesContainer{
text-align: center;
    gap: 10px;
}

.imagesContainer img:hover{
    filter: brightness(50%) ;

} 