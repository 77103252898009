.firstRow {
  margin: auto;
  max-width: 90vw;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.mottoName{
  font-size: 4rem;
}
/*
.firstRow ::before {
  content: "";
  position: absolute;
  width:500px;
  height: 500px;
  background-color: var(--main); 
  fill: red;
  color: red;
  overflow: hidden;
  border-radius: 50%;
  z-index: -10;
  top: -20%;
  right: 0%;
  box-shadow: none;
  text-shadow: none;

  filter: blur(50px) opacity(50%);
}
*/

.leftSection {
  max-width: 700px;
  text-align: center;
  margin: auto;
}

.motto {
  font-weight: 900;
  color: var(--main);
  font-size: 3rem;
  text-align: left;
  margin: auto;
  text-transform: capitalize;
  text-shadow:  0px 0px 10px var(--mainLighter);

}


.spiegazione {
  margin: auto;
  display: block;
  max-width: 500px;
  text-align: left;
  margin-top: 2rem;
  font-weight: 600;
}

.leftSection button {
  margin: 2rem;
}
.rightSection {
  text-align: center;
  width: 30vw;
}

.rightSection img {
  width: 70%;
  max-width: 500px;
}

.secondRow {
  background-color: var(--main);
  transform: translateY(-30px);
  padding: 20px;
}

.title {
  color: white;
  font-family: "Fredoka", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  text-align: center;
}
.steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  gap: 2rem;
}
.step {
  text-align: center;
  min-height: 150px;
  width: 200px;
  border-radius: 25% 75% 40% 60% / 56% 25% 75% 44%;
  background-color: #fff2cc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.step .num {
  color: var(--main);
  font-family: "Fredoka", Arial, Helvetica, sans-serif;
  font-size: 4rem;
}
.stepImg{
  height: 70px;
}
.step .text {
  font-size: 1.2rem;
  font-weight: 900;
  color: #434343;
  padding-bottom: 1rem;
}

.reverseWave {
  transform: rotate(180deg) translateY(51px);
}

.secondRow button {
  display: block;
  text-align: center;
  margin: auto;
}


.hpBackground{
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;


}


@keyframes load {
  0% {
      transform: scale(0.2);
  }
  100% {
      transform: scale(1);
  }
}


.cta{
  text-align: center;
  margin: auto !important;
display: block;

}

.container{
  max-width: 100%;
  overflow-x: hidden;
  box-sizing:border-box;
  scroll-behavior: smooth;
}

.firstIcon, .firstIconHover{
  position: absolute;
  width: 100%;
  min-width: 300px;
  margin: auto;


}

.iconContainer {
  position: relative;
  margin: auto;
  width: 30vw;
  min-width: 300px;
  aspect-ratio: 3/2.2;

}

.firstIconHover {
  position: absolute;
  margin: auto;
  opacity: 0;
  animation: fadeInOut1 4s ease-in-out infinite; /* Double the duration to switch between two images */
}

@keyframes fadeInOut1 {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.firstIconHover:nth-child(2) {
  animation: fadeInOut2 4s infinite; /* Double the duration to switch between two images */
}

@keyframes fadeInOut2 {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.subText{
  font-size: 1.2rem;
  max-width: 70%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-weight: 600;
  color: var(--mainDarker);
  text-align: left;
  /*
  text-align: justify;
  text-justify:inter-word;*/

}

.actionRow{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.actionRow>*{
  width: 50%;
}

.pros{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 100%;

}
.proContainer{
  border-radius: 10px;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
  width: 10rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--main);
  color: var(--bgWhite);
  fill: var(--bgWhite);
  padding: 1rem;
  border-radius: 10px;
  font-size: 1rem;

}

.proIcon{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  display: block;
  margin: auto;
  height: 3rem;
  width: 3rem;
}

.pros:nth-child(2n) {
  flex-basis: 100%;
}

.proTitle{
  width: 100%;
  text-align: center;
  color: var(--mainDarker);
  font-size: 2rem;
  font-weight: 900;
}

.proSection{
  background-color: var(--mainLighter);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10vw;
  row-gap: 2rem;
  flex-wrap: wrap;

}

.proSectionRight{
  text-align: center;
}

.violet{
  background-color: var(--lightViolet);
  color: var(--violet);
  fill: var(--violet);
  padding: 1rem;
  border-radius: 10px;
}

.yellow{
  background-color: var(--lightYellow);
  color: var(--yellow);
  fill: var(--yellow);
  padding: 1rem;
  border-radius: 10px;
}
.red{
  background-color: var(--lightRed);
  color: var(--red);
  fill: var(--red);
  padding: 1rem;
  border-radius: 10px;
}
.orange{
  background-color: var(--lightOrange);
  color: var(--orange);
  fill: var(--orange);
  padding: 1rem;
  border-radius: 10px;
}
.favic{
  margin: auto;
  max-width: 60px;
}
.actionProSection{
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
}
.actionLink{
  color: var(--mainDarker);
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.1rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
}
.actionLink:hover{
  color: var(--main);
}
.proContainerRow{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}