@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body,
#root,
.app {
  box-sizing: border-box;
  margin: 0;
  font-family: "Inter",Verdana, sans-serif;
  font-size: 15px;
}
@media screen and (max-width: 500px) {
  html {
    font-size: 13px;
  }
}
*,
::after,
::before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --main: #008c45;
  --mainLighter: #bfe8bc;

  --mainDarker: #083b06;
  --gold: #e2b100;
  --gold2: #ffd94e;

  --goldLighter: #fff1c0;
  --goldDarker: #9b7900;

  --secondary: rgb(255, 243, 209);
  --secondaryDarker: #d0c5ab;

  --bgWhite: rgb(243, 245, 243);

  --red: #b12222;
  --lightRed: #ffcece;
  --yellow: rgb(195, 156, 38);
  --lightYellow: #fff2ca;
  --orange: #e37827;
  --lightOrange: #ffe1ca;
  --blue: #2053c2;
  --lightBlue: #c4d4ff;
  --violet: rgb(88, 88, 186);
  --lightViolet: rgb(213, 213, 255);
  --white: #fff6f6;
  --black: rgb(57, 57, 57);
  --lightGray: #ebebeb;
  --darkGray: #5b5b5b;

  font-family: "Inter",Verdana, sans-serif;
  box-sizing: border-box;
  --shadow: 1px 1px 6px 1px #c9c9c9;
}
* {
  font-family: "Inter",Verdana, sans-serif;
  transition: all 0.3s ease-in-out;
}

body {
  max-width: 100%;
  background-color: var(--bgWhite);
}
/*
*::-webkit-scrollbar {
  display: none;
}

*{
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*/
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mainDarker);
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mainButtonGreen {
  background-color: var(--main);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 4px 6px 1px #0000001a;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.mainButtonGreen:hover {
  background-color: var(--main);
  color: white;
  -webkit-box-shadow: 0px 0px 15x 10px var(--mainLighter);
  box-shadow: 0px 0px 15px 10px var(--mainLighter);
}
.secondaryButtonGreen {
  background-color: var(--mainLighter);
  color: var(--mainDarker);
  font-weight: 800;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secondaryButtonGreen:hover {
  opacity: 0.5;
}
.secondaryButtonGreen svg {
  height: 20px;
  padding-right: 10px;
  fill: var(--mainDarker);
}

.buttonIcon {
  width: 2.5rem;
  padding-right: 1rem;
}

button:disabled {
  filter: grayscale(1);
  cursor: pointer;
}

.red {
  color: var(--red);
  fill: var(--red);
}
.white {
  color: white;
  fill: white;
}
.mainButtonRed {
  background-color: var(--red);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainButtonRed:hover {
  color: white;
  background-color: var(--red);
  -webkit-box-shadow: 0px 0px 15x 10px var(--lightRed);
  box-shadow: 0px 0px 15px 10px var(--lightRed);
}
.mainButtonOrange {
  background-color: var(--orange);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainButtonOrange:hover {
  color: var(--white);
  background-color: var(--orange);
}
.mainButtonBlue {
  background-color: var(--blue);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainButtonBlue:hover {
  color: var(--white);
  background-color: var(--blue);
}
.mainButtonYellow {
  background-color: var(--gold);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainButtonYellow:hover {
  color: white;
  background-color: var(--gold);
  -webkit-box-shadow: 0px 0px 15x 10px var(--goldLighter);
  box-shadow: 0px 0px 15px 10px var(--goldLighter);
}

.shadow {
  box-shadow: 1px 1px 6px 1px #c9c9c9;
}

.mainTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: clamp(1rem, 10vw, 1.5rem);
  color: var(--mainDarker);
  font-weight: 900;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.coolTitle {
  font-weight: 900;
  color: var(--main);
  font-size: 3rem;
  text-align: center;
  margin: auto;
  text-transform: capitalize;
  text-shadow: 0px 0px 10px var(--mainLighter);
}
.titleVariation {
  text-align: center;
  font-size: clamp(1rem, 10vw, 1.5rem);
  color: var(--main);
  font-weight: 900;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.secondaryTitle {
  display: block;
  max-width: 100%;
  text-align: left;
  font-size: 1.3rem;
  color: var(--mainDarker);
  font-weight: 900;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 2vw;
  padding-top: 0.5rem;
}
.secondaryTitle svg{
  flex-shrink: 0;
}

.tag {
  border-radius: 10px;
  background-color: var(--goldLighter);
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--goldDarker);
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-transform: capitalize;
}
.tag:hover {
  background-color: var(--yellow);
  color: white;
}

.smallTag {
  border-radius: 10px;
  background-color: var(--goldLighter);
  width: fit-content;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--goldDarker);
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.cta {
  background-color: var(--gold);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter",Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.cta:hover {
  background-color: var(--goldDarker);
}
.cta svg {
  height: 1.5rem;
  padding-right: 1rem;
  fill: white;
}

.redLink {
  all: unset;
  text-decoration: underline;
  color: var(--red);
  font-weight: 700;
  text-align: right;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
}

.greenLink {
  all: unset;
  text-decoration: underline;
  color: var(--main);
  font-weight: 700;
  width: 100%;
  cursor: pointer;
  text-align: right;
  display: flex;
  align-items: center;
}

.yellowLink {
  all: unset;

  text-align: right;
  text-decoration: underline;
  color: var(--goldDarker);
  font-weight: 700;
  width: 100%;
  cursor: pointer;
}
.breadCrumb {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
  gap: 5px;
  list-style: none;
  font-weight: 600;
  color: var(--black);
  padding-left: 10px;
}
.breadCrumb a {
  color: var(--main);
}
.breadCrumb a:hover {
  color: var(--mainDarker);
}
.loadingSkeleton{
min-width: 150px;
  height: 100px;
  border-radius: 10px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.5) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-squares 3s infinite ease-in-out;
}
@keyframes wave-squares {
  0% {
      background-position: -308px 0;
  }
   100% {
      background-position: 108px 0;
  }
}
