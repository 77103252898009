.container{
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-top:50px;
}
.logo{
    width: 70%;
    max-width: 300px;
    margin: auto;
    margin-bottom: 50px;

}

.positiveMex{
    font-weight: 900;
    font-size: 2.5rem;
    color: var(--white);
    width: fit-content;
    margin: auto;
}
.positiveMexParag{
    font-weight: 700;
    font-size: 1.2rem;
    color: var(--mainLighter);
    margin: auto;
    width: fit-content;
    margin-top: 2rem;
}
.container a{
    text-align: center;
    margin-top: 30px;
    margin: auto;

}

.hidden{
    color: var(--main);
    cursor: pointer;
    margin: auto;
    width: fit-content;
}

#svgFooter {
    bottom: 0;
    position: relative;
    width: 100vw;
    object-fit: cover;
    transform: translateY(5px);
    max-width: 100%;
  }

  .textSection{
    padding: 1rem;
    text-align: center;
    margin: auto;
    width: 100vw;
    height: 100vh;
    background-color: var(--main);
  }