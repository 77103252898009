.title {
  font-size: 2rem;
  font-weight: 900;
  color: var(--main);
  text-align: left;
  max-width: 1000px;
  margin: auto;
}

.filter {
max-width: 1000px;
margin: auto;
display: flex;
align-items: center;
font-weight: 600;
color: var(--mainDarker);
gap:5px;
margin-left: 2vw;
margin-top: 1rem;
font-size: 1rem;
}

.filter button{
    all: unset;
    font-size: 0.9rem;
    background-color: var(--main);
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
    padding: 0.3rem 0.8rem;
    border-radius: 4567px;
    display: flex;
    align-items: center;
}
.filter button svg{
    height: 1.3rem;
}
.filter button:hover{
    scale: 1.05;
}
.notSelectedType{
    background-color: gray !important;

}
.noResult{
    text-align: center;
}

.noResultText{
    text-align: center;
    font-weight: 700;
    color: var(--mainDarker);
    max-width: 400px;
    box-sizing: border-box;
    margin: auto;

}

.image{
    height: 200px;
    max-width: 100vw;

}

.selection{
    padding: 10px 20px;
    border: none;
    display: block;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 800;
    color: var(--mainDarker);
    font-size: 1rem;
    display: inline;
    text-transform: capitalize;

}

.titleTag div{
    display: inline;
    font-weight: 800;
    font-size: 1rem;

}

.contactReminder{
    background-color: var(--mainLighter);
    color: var(--mainDarker);
    border-radius: 10px;
    width: 95vw;
    max-width: 500px;
    margin: auto;
    padding: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-evenly;
    margin-top: 1rem;
}

.contactReminderIcon{
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    color: var(--mainDarker);
}

.contactReminderText{
    max-width: 600px;
}