.container{
    
    
   align-items: center;
    text-align: center;
    border-radius: 10px;


    min-height: 200px;
    max-width: 100%;
    background-color: white;
    margin: auto;
    margin-top:1rem;
    margin-bottom:1rem;
    padding: 1rem;

}

.card{
    padding: 2vw;
    border-radius: 20px;
    align-items: center;
    width: 80%;
    text-align: center;
    margin: auto;
    margin-bottom: 1rem;
}
.cardLink{
    color: gray;
    font-weight: 600;
    float: left;
    cursor: pointer;
    text-decoration: underline;

}
.cardLink:hover{
    color: var(--main);

}
.firstRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.cardTitle{
    font-weight: bolder;
    color: var(--mainDarker);
    font-size: 1.5rem;
    text-align: left;
}

.order{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid rgb(178, 178, 178) 1px ;
}

.products{
    cursor: pointer;
}

.seeMore{
    color: var(--main);
    margin-top: 2rem;
    display: block;

}

.noOrders{
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2rem;
    color: gray;
}

.selection{
    padding: 10px 20px;
    border: none;
    display: block;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
    background-color: var(--bgWhite);
    font-weight: 600;    
    color: var(--mainDarker);

}

.ordersTable{
    width: 100%;
    border: 0px;
    padding: 0rem 1vw;
    margin-top: 2rem;
      margin-bottom: 2rem;

}
tr{
    cursor: pointer;
}
th, td{
    text-align: left;
    color: gray;
    margin-bottom: 1rem;
    border: 0px;
    font-size: 1rem;
        margin: auto;

}
th{
    cursor:auto;
}
thead{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.row{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main);
    font-weight: 700;
    width: fit-content;
}

.label{
    width: fit-content;
    background-color: gray;
    color: white;
    font-weight: 600;
    padding: 0.2rem 0.5rem;
    border-radius: 1565px;
    font-size: 0.9rem;
}
@media screen and (max-width: 800px) {
    .IDColumn, .ID{
        display: none;
    }
    thead, th, td, label{
        margin: auto;
        
        text-align: center;
    }
  }