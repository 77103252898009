.container{
    padding: 1em;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    max-width: 2000px;

}
.icon{
    max-width: 200px;
    display: block;
    margin: auto;
}

.positiveMex{
    font-weight: 900;
    font-size: 1.6em;
    color: var(--main);
    margin-bottom: 1rem;
    text-align: center;
}
.positiveMexParag{
    font-weight: 500;
    font-size: 1rem;
    max-width: 500px;
    color: var(--mainDarker);
    text-align: left;
    margin-bottom: 1rem;
}
.container a{
    text-align: center;
    margin-top: 30px;

}

.orders{
    flex-grow: 1;

}
.orders> *{
    margin-top: 1rem;
}
.textContainer{
    background-color: white;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-right: 1rem;
    margin-top: 1rem;

}