.container {
    margin: auto;
    overflow:hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

}

.carousel{
    display: flex;
    align-items: center;
    justify-content: left;
    max-width: 100vw;
    flex-wrap: wrap;
    padding: 2vw;
    gap: 2rem;

    
}

@media screen and (max-width: 1000px) {
    .carousel{
        justify-content: left;
        gap: 10px;
        padding: 1rem;

    }
  }
.arrow{
    width: 4rem;
}

.buttonArrow{
    background-color: transparent;
    border: none;
    fill: var(--main);
    z-index: 1000000;
    cursor: pointer;
    position: absolute;
    margin: 1rem;

}

.buttonArrow:hover{
    transform: scale(1.1);
}

.buttonArrowRight{
    background-color: transparent;
    border: none;
    fill: var(--main);
    z-index: 1000000;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin: 1rem;
    
}

.buttonArrowRight:hover{
    transform: scale(1.1);
}

.voidList{
    margin: auto;
    color: gray;
    font-weight: 700;
}