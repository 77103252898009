.container {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  animation: all 1s ease-in-out;
}

.carousel {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: left;
  max-width: 100vw;
  overflow-x: scroll;
  flex-wrap: nowrap;
  padding-bottom: 13px;
  padding-right: 13px;
  margin-left: 5vw;
  position: relative;
}
.arrow {
  width: 4rem;
}
.container:hover > .buttonArrow,
.container:hover > .buttonArrowRight {
  display: block;
}
.buttonArrow {
  display: none;
  background-color: transparent;
  border: none;
  fill: var(--gold);
  z-index: 1000000;
  cursor: pointer;
  position: absolute;
  opacity: 0.8;
}

.buttonArrow:hover {
  opacity: 1;
  transform: scale(1.1);
}

.buttonArrowRight {
  display: none;
  border: none;
  background-color: transparent;

  fill: var(--gold);
  z-index: 1000000;
  cursor: pointer;
  position: absolute;
  right: 0;
  opacity: 0.8;
}

.buttonArrowRight:hover {
  transform: scale(1.1);
  opacity: 1;
}

.totContainer {
  margin: 10px;
}

@media screen and (max-width: 500px) {
  .arrow {
    display: none;
  }
}

.noProduct {
  min-width: 180px;
  width: 180px;
  height: 250px;
  border-radius: 10px;
  color: var(--mainDarker);
  overflow: hidden;
  box-shadow: 1px 4px 6px 1px #0000001a;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin: auto;
  text-transform: capitalize;
  background-color: white;
  font-weight: 800;
  text-align: center;
  flex-wrap: nowrap;
  padding: 1rem;
}

.noProduct svg {
  display: block;
  fill: var(--mainDarker);
  width: 3rem;
  margin: auto;
  margin-top: 50px;
}
