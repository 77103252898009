.imageContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000000;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  
  .activeImg {
    display: block;
    width: 100vw;
    height: 90vh;
    max-width: 100vw;
    max-height: 90vh;
    object-fit: contain;
    z-index: 2;
    border-radius: 10px;
  }
  
  .activeImgCont {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
  }
  
  .leftImage,
  .rightImage {
    position: absolute;
    height: 90vh;
    width: 20vw;
    object-fit:cover;
    filter: brightness(50%);
    cursor: pointer;
    z-index: 1;
    transition: transform 0.3s ease;
  }
  
  .leftImage {
    left: 10px;

  }
  
  .rightImage {
    right: 10px;

  }
  
  /* Adjust the hover transformation for better visual transition */
  .leftImage:hover {
    transform: translateX(5px);
  }
  
  .rightImage:hover {
    transform: translateX(5px);
  }

.button{
    all: unset;
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    color: var(--white);
    font-weight: 900;
    height: 3rem;
    cursor: pointer;
    fill: white;
    z-index: 10000000000000;

}

.button svg{
  height: 2rem;
  width: 2rem;
}
.button:hover{
    opacity: 0.5;
    transition: all ease-in 0.1s;
}


.arrow {
  width: 4rem;
  height: 4rem;
  color: var(--white);
  filter: drop-shadow(0px 0px 4px var(--mainDarker));
}
.buttonArrow, .buttonArrowRight {
  display: block;
  background-color: transparent;
  border: none;
  fill: var(--gold);
  z-index: 1000000;
  cursor: pointer;
  position: fixed;
  top: 50%;
  left: 0;
}
.buttonArrowRight{
  left: auto;
  right: 0 ;
}
.buttonArrow:hover, .buttonArrowRight:hover {
  opacity: 1;
  transform: scale(1.1);
}

.dotsConatiner{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}

.dot{
  height: 1rem;
  width: 1rem;
  background-color: lightgray;
  cursor: pointer;
  border-radius: 1000%;
  margin-top: 1rem;
}

.activeDot{
  background-color: white;
  transform: scale(1.5);
  cursor:auto;

}