

.optionButton{
    width: fit-content;
    height: fit-content;
    border-radius: 349px;
    border: none;
    color: var(--main);

}

.selected{
    fill: var(--main) !important;
}

.ratingButtonsContainer{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: left;

}

.ratingButtonsContainerInline{
    display: flex;

    align-items: center;
    justify-content: left;
}
.ratingButtonsContainerInline svg{
    height: 1.5rem;
}
.textRating{
    font-weight: 700;
    color: var(--main);
    height: 100%;
}

.gray{
    filter: grayscale(100%);
}

.clickable{
    cursor: pointer;
}