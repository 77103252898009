.title {
  font-size: 2rem;
  font-weight: 900;
  color: var(--main);
  text-align: left;
  max-width: 1000px;
  margin: auto;
}

.filter {
max-width: 1000px;
margin: auto;
display: flex;
align-items: center;
font-weight: 700;
color: var(--mainDarker);
gap:5px;
margin-left: 5px;
}

.filter button{
    padding: 10px 20px;
    font-size: 0.8rem;
    font-weight: 800;
    border: none;
    border-radius: 12345px;
    cursor: pointer;
    color: var(--main);
    background-color: var(--mainLighter);
}
.filter button:hover{
    scale: 1.05;

}
.notSelectedType{
    filter: saturate(0);

}
.noResult{
    text-align: center;
}

.noResultText{
    text-align: center;
    font-weight: 700;
    color: var(--mainDarker);
    max-width: 400px;
    box-sizing: border-box;
    margin: auto;

}

.image{
    height: 200px;
    max-width: 100vw;

}

.tags{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 90%;
    margin: auto;
}
.tags div{
    width: fit-content;

}

.searchFilter{
    box-sizing: border-box;
    height: 40px;
    padding: 7px;
    width: 300px;
    max-width: 100%;
    border: none;
    border-radius: 900000px;
    outline: none;
    padding-left: 1rem;
    color: var(--mainDarker);
    background-color: white;
    font-weight: 600;
    box-shadow: 3px 4px 6px 1px #0000001A;
    margin-bottom: 2rem;
    margin-top: 2rem;

}