.conversation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2vw ;
    cursor: pointer;
    color: var(--mainDarker);
    font-weight: 600;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    border-radius: 10px;
    position: relative;
}
.conversation:hover{

background-color: var(--bgWhite);

}
.conversationActive{
    padding: 0.5rem;
    cursor:default;


}
.conversationActive:hover{
    background-color: transparent !important;
}
.conversationIcon{
    background-color: var(--main);
    border-radius: 100%;
    min-height: 1.5rem;
    min-width: 1.5rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: 900;
    margin-right: 1rem;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    object-fit: cover;

}

.conversationName>*:first-child {
    font-weight: 800;
    font-size: 1.1rem;
}
.conversationName>*:nth-child(2) {
    font-weight: 600;
    color: gray;
    font-size: 0.9rem;
}
.notification{
    background-color: var(--red);
    border-radius: 3456789px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    position: absolute;
    font-size: 1rem;
    left: 0.5rem;
    top: 1rem;

}



.callIcon{
    color: var(--mainDarker);
    width:30px;

}
.callIcon:hover{
    color: var(--main);

}
.left{
    display: flex;
    justify-content: left;
    align-items: center;
}