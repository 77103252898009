.container{
    padding: 1em;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1100px;
    margin: auto;
}

.productRow{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    max-width: 800px;
    margin: auto;
    justify-content: space-between;
}

.paragarph{
    font-weight: 700;
    color: var(--mainDarker);
    font-size: 1.2rem;

}

.checkbox{
    height: 1.2rem;
    width: 1.2rem;
}

.textArea{
    margin-top: 2rem;
    border-radius: 10px;
    max-width: 100%;
    padding: 1rem;
    border: 2px solid var(--main);
    font-weight: 600;
}

.actions{
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: right;
}

.quantityInput{
    padding: 2px;
    border: 2px solid var(--main);
    width: 5rem;
    border-radius: 5px;
    font-weight: 600;
    padding-left: 0.5rem;

}

.orderRecap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: white;
    padding: 2vw;
    border-radius: 10px;
    font-weight: 600;
    color: gray;
    margin: 2rem;
}

.productList{
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
}
.left{
    display: flex;
    align-items: center;
    gap: 5px;
}