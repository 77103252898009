.form{
    width: 100%;
    margin: auto;
}
.container{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 1rem;
    align-items: center;
    border-radius: 10px;
    background-color: var(--bgWhite);
}
.userImage{
    height: 70px;
    width: 70px;
    border-radius: 14567890px;
}
.userIcon{
    height: 3rem;

}
.userData{
    display: flex;
    align-items: center;
}
.userName{
    font-weight: 700;
    color: var(--mainDarker);
    margin: 1rem;
}
.ratingContainer{
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}


.content{
    display: block;
    margin: auto;
    margin-top: 1rem;
    font-weight: 600;
    padding: 1.5rem;
    border: none;
    border-radius: 10px;
    outline: none;
    border: solid var(--main) 2px;
    flex-grow: 1;

}

.title{
    font-weight: 700;
    color: var(--main);
    margin: 1rem;
    text-align: center;
    width: 100%;

}

.optionButton{
    width: 30px;
    height: 30px;
    background-color: gray;
    border-radius: 3456789px;
    border: none;
    cursor: pointer;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);


}
.optionButton:hover{
    background-color: var(--mainLighter);

}
.selected{
    background-color: var(--main) !important;
}

.ratingButtonsContainer{
    display: flex;
    gap: 5px;
    align-items: center;

}

.ratingsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    align-items: center;   
    max-width: 700px;
    margin: auto;

    
}

.form button{
    margin: auto;
}

.sent{
    font-weight: 800;
    color: var(--black);
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
}

.ratingLable{
    font-size: 1rem;
    font-weight: 600;
    color: gray;
}

.closeButton {
    all: unset;
    cursor: pointer;
    opacity: 0.6;
    font-weight: 900;
    font-size: 1.5rem;
    text-align: right;
    width: fit-content;
    margin: auto;
    margin-right: 0;
    color: var(--main);
    float: left;
  }
  .closeButton:hover {
    opacity: 0.5;
    }
      .newRev{
    margin: auto;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .title{
    color: var(--mainDarker);
    text-align: left;
    
  }

  .contentRow{
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    justify-content: center;
    max-width: 700px;
    margin: auto;
    flex-wrap: wrap;
  }

  .contentRow button{
    width: fit-content;
    margin: 0;
    flex: 1;
  }


  .firstRow{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }