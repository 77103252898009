
.redeemCodeSection{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.redeemCodeSection label{
  font-weight: 600;
  color: var(--mainDarker);
}

.redeemCodeSection input{
    border-radius: 5px;
    height: 40px;
    border: 2px solid var(--main);
    padding: 5px;
    padding-left: 5px;
    width: 6rem;
    font-size: 1rem;
    font-weight: 500;

}
