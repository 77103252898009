.dataContainer{
    display: flex;
    align-items: center;
justify-content: center;
flex-wrap: wrap;
column-gap: 1rem;

}

.dataContainerSection1, .dataContainerSection2{
    display: flex;
    align-items: center;
justify-content: center;
margin-bottom: 1rem;
flex-wrap: wrap;
gap: 1rem;
flex-grow: 1;

}
.container{
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 1rem;
    padding: 2vw;
    
}
.num{
    font-weight: 900;
    color: var(--mainDarker);
    font-size: 1.2rem;
    margin: 5px;
}

.data{
    background-color: var(--bgWhite);
    border-radius: 10px;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-grow: 1;
    flex-wrap: wrap;

}

.dataTitle{
    font-weight: 700;
    color: var(--main);
}

.data svg{
    height: 2.5rem;
    width: 2.5rem;
    color: var(--main);
}
.link{
    width: fit-content;
    text-align: right;
    margin: auto;
    margin-right: 0;

}
.explanation{
    font-weight: 500;
    color: var(--mainDarker);
    margin: auto;
    margin-top: 0.5rem;

}
.firstRow{
    margin-bottom: 1rem;
}
.littleTitle{
    font-weight: 700;
    color: var(--mainDarker);
}

.request{
    max-width: 1000px;
    margin: auto;
    margin-top: 1rem;

}

.request button{

    margin-right: 0;
    margin: 1rem;

}

.actionRow{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.storeDataContainer{
    flex-grow: 1;
    margin-top: 1rem;
}

.iban{
    color: gray;
    display: flex;
    align-items: center;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 1rem;
    word-break: break-all;

}
.iban span{
    color: var(--mainDarker);
}

.creditSection{
    
    background-color: var(--bgWhite);
    border-radius: 10px;
    padding: 1rem ;
}