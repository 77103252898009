.dropFile{

    object-fit: cover;

    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .plusIcon{
    width: 2rem;
  }

.removeOldImage{
    all: unset;
    height: 30px;
    width: 30px;
    font-weight: 800;
    border-radius: 50%;
    background-color: var(--lightRed);
    color: var(--red);
    cursor: pointer;
    font-size: 20px;
    margin: 0 !important;
    transform: translateX(70px) translateY(-100px);
    z-index: 10;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .newImageContainer{
    width: 100px;
    height: 100px;
    margin: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 456789px;
    color: var(--main);
    background-color: var(--mainLighter);
    font-weight: 900;
    font-size: 1rem;
  }
  .newImageContainer:hover{
    background-color: var(--mainDarker);
    color: white;
  }
  .card{
    width: fit-content;

  }
  .titleRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
  .dropFile{
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 800;
    height: fit-content;
  }

  .form button{
    text-align: center;
    margin: auto;
  }

  .sendButton{
    border: none;
    width: 90px;
    height: 90px;
    margin: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 456789px;
    background-color: var(--main);
    color: white;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
  }

  .sendButton:hover{
    background-color: var(--mainDarker);

  }

  .removeSVG{
    fill: var(--red);
    height: 18px;
  }

  
  .removeOldImage{
    all: unset;
    height: 30px;
    width: 30px;
    font-weight: 800;
    border-radius: 50%;
    background-color: var(--lightRed);
    color: var(--red);
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0 !important;
    transform: translateX(-15px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.oldImage{
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 1000000px;
}

.oldImageContainer{
  margin: 0.5rem;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}