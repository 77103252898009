.titlo{
    font-size: 1.5rem;
    color: var(--mainDarker);
    margin: 10px;
    font-weight: 900;
    text-align: left;
    text-transform: capitalize;
}

.container{
    padding: 1rem;
}

.actionsContainer{
    text-align: right;
}

.actionsContainer button{
    margin:10px;
}

.dataContainer{
    max-width: 1200px;
    width: 100%;
    height:inherit;
    display: flex;
    flex-direction: column;
    align-items:start;
    padding: 40px;
    background-color: white;
    border-radius: 20px;
    margin-top: 20px;


}

.shareIcon{
    width: 25px;
    fill: var(--mainDarker);
}
.data{
    font-weight: 700;
    font-size: 1.2rem;
    color: var(--main);
}

.lable{
    color: gray;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;

}
.secondContainer{

    margin: 2rem;

}
.secondContainer .title{
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
    color: var(--main);
}
.box{
    text-align: center;
    width: 300px;
    height: 400px;
    background-color: var(--secondary);
    padding: 1rem;
    font-weight: bolder;
}

.storeImg{
    height: 300px;
    text-align: center;
    margin: auto;
    display: block;
    object-fit: cover;
    border-radius: 10px;
}

.tagContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
}

.storeRow{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    text-align: left;
    max-width: 1500px;
    gap: 10px;
    margin-top: 0;
    height: fit-content;
}

.address{
    width: 100%;
    font-weight: 700;
    font-size: 1rem;
    color: var(--mainDarker);
}


.phone{
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    color: var(--black);
    margin-top: 1rem;
}


.socialIcon{
    fill: var(--main);
    height: 1.5rem;
}

.socialIcon:hover{
    fill: var(--mainDarker);
    cursor: pointer;
}
.social{
    text-align: center;
    display: flex;
    gap: 1rem;
}

.description{
    font-size: 1rem;
    font-weight: 500;
    color: var(--black);
    width: 100%;
    max-width: 400px;
    
    /*
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;*/

}

.textArea{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;

}
.separator{
    border: 1px solid gray;
}
@media screen and (max-width:900px) {
    .separator{
        display: none;
    }
  }
.info{
    max-width: 400px;
    padding: 5px;
}
.showMore{
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    color: var(--mainDarker);
    margin: 0.5rem;

}


.firstRow{
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
    gap: 10px;
    
}
.edit{
    width: fit-content;
    white-space:nowrap
}
.rightButtons{
    display: flex;
    gap: 5px;
}
.owner{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 10px;
}

.userImage{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.userName{
    color: var(--black);
    font-weight: 700;

}


.openStore{
    background-color: var(--main);
    color: white;
    padding: 0.5rem;
    border-radius: 10px;
    text-align: center;
    max-width: 1000px;
    margin: auto;
    margin-top: 1rem;
    font-weight: 800;

}