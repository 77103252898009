.container{
    
    height: fit-content;
    text-align: left;
    width: 400px;
    max-width: 95vw;
    background-color: white;
    border-radius: 10px;
    margin: auto;
    padding: 2vw;
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: 1000000000000000;
    box-shadow: 1px 4px 6px 1px #0000001A;
    transition: all 1s ease-in-out;

}

.title{
    font-weight: 800;
    font-size: 1.1rem;
    color: var(--main);
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.text{
    font-weight: 600;
    color: var(--mainDarker);
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    font-size: 1rem;
}