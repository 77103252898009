.container {
  background-color: white;
  width: 95vw;
  max-width: 1000px;
  color: var(--red);
  margin-top: 10px;
  border-radius: 10px;
  padding: 1rem;
  margin: auto;
  margin-bottom: 2rem;
  
}

.firstRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card{
  width: 100%;
}
.icon{
  height: 1.2rem;
}

.iconTitle{
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
}
.cardLink{
  color: gray;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  justify-content: right;
  text-align: right;
  width: 100%;
  margin-top: 0.5rem;
}
.cardLink:hover{
  color: var(--main);

}


.dataContainer {
  align-items: center;
  text-align: center;
  margin: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
  gap: 1rem;
  height: 100%;
}


.container p {
  font-weight: 600;
  display: flex;
  justify-content: left;
  align-items: center;
  width: fit-content;
  margin: auto;

}

.number {
    display: inline-block;

    width: 30px;
      font-weight: 900;
      font-size: 2rem;
      align-items: center;
      justify-content: center;
  color: var(--mainDarker);
  margin-right: 2px;

}


.link {
  text-decoration: underline;
  cursor: pointer;
}

.container a{
    width: fit-content;
    text-align: center;

}




.data{
  background-color: var(--bgWhite);
  border-radius: 10px;
  padding: 2rem 2rem;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  flex-grow: 1;
}

.dataTitle{
  font-weight: 700;
  color: var(--main);
}

.data svg{
  height: 2.5rem;
  width: 2.5rem;
  color: var(--main);
}
.num{
  font-weight: 900;
  color: var(--mainDarker);
  font-size: 1.2rem;
  margin: 5px;
  text-align: left;
}
.cardLink svg{
  height: 1.2rem;
  width: fit-content;
  color: gray;
  font-weight: 900;
}