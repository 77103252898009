.container{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin-bottom: 1rem;
  z-index: 9999999999999999999999999999999999999;
  pointer-events: none;
}
.card{
  width: 100%;
  max-width: 1000px;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  margin:auto;
  pointer-events:all;

}

.message{
  max-width: 80%;
}
.info{
  background-color: var(--lightBlue);
  border: 2px solid var(--blue);
  color: var(--blue);
}
.error{
  background-color: var(--lightRed);
  border: 2px solid var(--red);
  color: var(--red);
}
.warning{
  background-color: var(--lightYellow);
  border: 2px solid var(--yellow);
  color: var(--yellow);
}
.success{
  background-color: var(--mainLighter);
  border: 2px solid var(--main);
  color: var(--main);
}
.label{
  font-weight: 800;
}
.closeButton{
  all: unset;
  cursor: pointer;
  opacity: 0.6;
  font-weight: 900;
}

.closeButton:hover{
  opacity: 1;
}

.infoIcon{
  width: 2rem;
  color: var(--blue);
  fill: var(--blue)!important;

}

.warningIcon{
  width: 2rem;
  color: var(--yellow);
  fill: var(--yellow)!important;

}
.errorIcon{
  width: 2rem;
  color: var(--red);
  fill: var(--red)!important;

}
.successIcon{
  width: 2rem;
  color: var(--main);
  fill: var(--main)!important;

}